<template>
  <b-card class="border border-dark shadow-none">
    <div>
      <b-row>

        <!-- #region::Title -->
        <b-col class="align-self-end">
          <h4 class="font-weight-bolder">
            Dirección de envío
          </h4>
        </b-col>
        <!-- #endregion::Title -->

        <!-- #region::Collapsable options -->
        <b-col
          cols="12"
          md="auto"
          class="ml-auto"
        >

          <!-- #region begin::Collapse card button -->
          <b-button
            :variant="visible ? 'flat-primary' : 'flat-secondary'"
            class="btn-icon rounded-circle ml-1"
            @click="collapse"
          >
            <feather-icon :icon="iconToggle" />
          </b-button>
          <!-- #endregion end::Collapse card button -->

        </b-col>
        <!-- #endregion::Collapsable options -->

      </b-row>
    </div>

    <b-collapse
      :id="collapseItemId"
      v-model="visible"
      role="tabpanel"
    >
      <div>
        <hr>
        <h4 class="font-weight-bolder">
          {{ order.address.ContactName }}
        </h4>
      </div>
      <div class="text-primary">
        {{ order.address.Email }}
      </div>
      <div>
        {{ order.address.Phone }}
      </div>
      <div>
        {{ order.address.Address }}
      </div>
      <div>
        {{ order.address.PostalCode }}
      </div>
      <div>
        {{ order.address.Neighborhood }}
      </div>
      <div>
        {{ order.address.City }}
      </div>
      <div>
        {{ order.address.State }}
      </div>
    </b-collapse>

  </b-card>
</template>

<script>
// #region Imports
import {
  BCol, BButton, BCard, BRow, BCollapse,
} from 'bootstrap-vue'
// #endregion

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCollapse,
  },
  props: {
    order: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      visible: false,
      collapseItemId: '2',
      iconToggle: 'ChevronDownIcon',
    }
  },
  methods: {
    collapse() {
      this.$root.$emit('bv::toggle::collapse', this.collapseItemId)
      this.visible = !this.visible
      this.setToggleIcon()
    },
    setToggleIcon() {
      this.iconToggle = this.visible ? 'ChevronUpIcon' : 'ChevronDownIcon'
    },
  },
}
</script>
