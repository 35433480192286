<template>
  <div>
    <b-row>

      <!-- #region::Count to invoice data -->
      <b-col
        v-show="editionModeState"
        md="4"
      >
        <CountToInvoice />
      </b-col>
      <!-- #region::Count to invoice data -->

      <!-- #region::Total order data -->
      <b-col
        v-if="$ability.can('read total_quotation', 'Order')"
        md="4"
      >
        <b-card class="border border-dark shadow-none">
          <div>
            <b-row>

              <!-- #region::Title -->
              <b-col class="align-self-end">
                <h4 class="font-weight-bolder">
                  Total de la orden
                </h4>
              </b-col>
              <!-- #endregion::Title -->

              <!-- #region begin::Collapse card button -->
              <b-button
                :variant="visible ? 'flat-primary' : 'flat-secondary'"
                class="btn-icon rounded-circle mr-1"
                @click="collapse"
              >
                <feather-icon :icon="iconToggle" />
              </b-button>
              <!-- #endregion end::Collapse card button -->

            </b-row>
          </div>

          <b-collapse
            :id="collapseItemId"
            v-model="visible"
            role="tabpanel"
          >
            <div>
              <hr>
              <div class="d-flex justify-content-between">
                <span>Subtotal USD</span>
                <span>{{ subtotalUSD | currency }}</span>
              </div>
              <hr>
              <div class="d-flex justify-content-between">
                <span>Tipo de cambio</span>
                <span>{{ order.total_de_cotizacion.ExchangeRate | currency }}</span>
              </div>
              <hr>
              <div class="d-flex justify-content-between">
                <span>Descuento</span>
                <span v-if="order.total_de_cotizacion.Discount">{{ `${order.total_de_cotizacion.Discount}%` || '0%' }}</span>
                <span v-else>0.00%</span>
              </div>
              <hr>
              <div class="d-flex justify-content-between">
                <span>Subtotal MXN</span>
                <span>{{ subtotalMXN | currency }}</span>
              </div>
              <hr>
              <div
                v-if="invoiceRequired === 'Sí' && countToInvoice"
                class="d-flex justify-content-between"
              >
                <span>Monto a facturar</span>
                <span>{{ countToInvoice | currency }}</span>
              </div>
              <hr v-if="invoiceRequired === 'Sí' && countToInvoice">
              <div class="d-flex justify-content-between">
                <span>IVA</span>
                <span>{{ IVA | currency }}</span>
              </div>
              <hr>
              <div class="d-flex justify-content-between">
                <span>Envío</span>
                <span>{{ order.total_de_cotizacion.ShippingCost | currency }}</span>
              </div>
              <hr>
              <div class="d-flex justify-content-between">
                <span>Instalación</span>
                <span>{{ order.total_de_cotizacion.InstallationCost | currency }}</span>
              </div>
              <hr>
              <div class="d-flex justify-content-between font-weight-bolder">
                <span>Total MXN</span>
                <span>{{ totalMXN | currency }}</span>
              </div>
            </div>
          </b-collapse>

        </b-card>
      </b-col>
      <!-- #endregion::Total order data -->

      <!-- #region::Invoice data -->
      <b-col
        v-if="$ability.can('read billing_data', 'Order')"
        md="4"
      >
        <InvoiceData :order="order" />
      </b-col>
      <!-- #region::Invoice data -->

      <!-- #region::Donwloading tax situation proof -->
      <b-col
        v-if="canShowDownloadTaxSituationProof"
        md="4"
      >
        <QuoteDownloadTaxSItuationProof
          :id-model="order.order.ID"
          :id-model-type="'orderID'"
          :file-url="order.order.tax_situation"
          :file-name="order.order.tax_situation_name"
          @reload-order="$emit('reload-order')"
        />
      </b-col>
      <!-- #endregion::Donwloading tax situation proof -->

      <!-- #region::Donwloading tax situation proof -->
      <b-col
        v-if="canShowUplaodTaxSituationProof"
        md="4"
      >
        <OrderUploadTaxSituationProof
          :id-model="order.order.ID"
          @reload-order="$emit('reload-order')"
        />
      </b-col>
      <!-- #endregion::Donwloading tax situation proof -->

    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BRow, BCol, BCard, BButton, BCollapse,
} from 'bootstrap-vue'

import InvoiceData from '@/modules/trade/orders/components/form/InvoiceData.vue'
import CountToInvoice from '@/modules/trade/orders/components/form/CountToInvoice.vue'
import OrderUploadTaxSituationProof from '@/modules/trade/orders/components/OrderUploadTaxSituationProof.vue'
import QuoteDownloadTaxSItuationProof from '@/modules/trade/quotes/components/QuoteDownloadTaxSItuationProof.vue'

// Estados en los que no se puede cargar comprobante de situación fiscal
const REJECTED_STATUS = ['Cancelada']

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCollapse,
    InvoiceData,
    CountToInvoice,
    OrderUploadTaxSituationProof,
    QuoteDownloadTaxSItuationProof,
  },
  data() {
    return {
      visible: false,
      quoteInfo: null,
      collapseItemId: '4',
      iconToggle: 'ChevronDownIcon',
    }
  },
  computed: {
    ...mapGetters({
      getIVA: 'orders/getIVA',
      getOrder: 'orders/getOrder',
      getTotalMXN: 'orders/getTotalMXN',
      getSubtotalUSD: 'orders/getSubtotalUSD',
      getSubtotalMXN: 'orders/getSubtotalMXN',
      getCountToInvoice: 'orders/getCountToInvoice',
      getInvoiceRequired: 'orders/getInvoiceRequired',
      getEditionModeState: 'orders/getEditionModeState',
    }),
    /**
     * Total de la orden en MXN calculado en tiempo real
     */
    totalMXN: {
      get() { return this.getTotalMXN },
    },
    /**
     * Información de la orden
     */
    order: {
      get() { return this.getOrder },
    },
    /**
     * IVA calculado en tiempo real
     */
    IVA: {
      get() { return this.getIVA },
    },
    /**
      * Subtotal en USD de la orden
      */
    subtotalUSD: {
      get() { return this.getSubtotalUSD },
    },
    /**
     * Subtotal en MXN de la orden
     */
    subtotalMXN: {
      get() { return this.getSubtotalMXN },
    },
    /**
     * Monto a facturar
     */
    countToInvoice: {
      get() { return this.getCountToInvoice },
    },
    /**
     * Indica si la orden requiere factura
     */
    invoiceRequired: {
      get() { return this.getInvoiceRequired },
    },
    /**
     * Indica si la orden se encuentra en modo edición
     */
    editionModeState: {
      get() { return this.getEditionModeState },
    },
    /**
     * Estado de la orden
     */
    status: {
      get() { return this.order.order.current_status },
    },
    /**
     * Indica si se puede mostrar el botón de descarga de comprobante de situación fiscal
     * @resumen Verifica que haya un comprobante de situación fiscal cargado y que el usuario
     * tenga permisos para descargar comprobante de situación fiscal
     */
    canShowDownloadTaxSituationProof: {
      get() {
        return (this.order.order.tax_situation && this.order.order.tax_situation_name)
        && this.$ability.can('download tax situation', 'Order')
      },
    },
    /**
     * Indica si se puede mostrar el botón de subir comprobante de situación fiscal
     * @resumen Verifica que no haya un comprobante de situación fiscal cargado,
     * que no se encuentre en estado cancelada y que el usuario tenga permisos para subir
     * comprobante de situación fiscal
     */
    canShowUplaodTaxSituationProof: {
      get() {
        return !this.canShowDownloadTaxSituationProof
          && !REJECTED_STATUS.includes(this.status)
          && this.$ability.can('save tax situation', 'Order')
      },
    },
  },
  methods: {
    collapse() {
      this.$root.$emit('bv::toggle::collapse', this.collapseItemId)
      this.visible = !this.visible
      this.setToggleIcon()
    },
    setToggleIcon() {
      this.iconToggle = this.visible ? 'ChevronUpIcon' : 'ChevronDownIcon'
    },
  },
}
</script>
