<template>
  <b-card class="border border-dark shadow-none">
    <div>
      <b-row>

        <!-- #region::Title -->
        <b-col class="align-self-end">
          <h4 class="font-weight-bolder">
            Solicitud de facturación
          </h4>
        </b-col>
        <!-- #endregion::Title -->

        <!-- #region::Collapsable options -->
        <b-col
          cols="12"
          md="auto"
          class="ml-auto"
        >

          <!-- #region begin::Collapse card button -->
          <b-button
            :variant="visible ? 'flat-primary' : 'flat-secondary'"
            class="btn-icon rounded-circle ml-1"
            @click="collapse"
          >
            <feather-icon :icon="iconToggle" />
          </b-button>
          <!-- #endregion end::Collapse card button -->

        </b-col>
        <!-- #endregion::Collapsable options -->

      </b-row>
    </div>

    <b-collapse
      :id="collapseItemId"
      v-model="visible"
      role="tabpanel"
    >
      <div>
        <hr>

        <!-- #region::Invoice required radiobuttons -->
        <b-col
          md="12"
          class="mt-1"
        >
          <b-form-group>
            <label for="basic-password">Solicitud de factura</label>
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="invoiceRequired"
                value="Sí"
                name="type-radios"
                class="mt-0"
              >
                Sí
              </b-form-radio>
              <b-form-radio
                v-model="invoiceRequired"
                value="No"
                name="type-radios"
                class="mt-0"
                checked
              >
                No
              </b-form-radio>
            </div>
          </b-form-group>
        </b-col>
        <!-- #endregion::Invoice required radiobuttons -->

        <!-- #region::Count to be invoiced input -->
        <b-col
          v-if="invoiceRequired === 'Sí'"
          md="12"
        >
          <TextInputWithValidation
            v-model="countToInvoice"
            vid="countToInvoice"
            :rules="{
              required: false,
              negative,
              notalpha,
              decimal,
              'min_value': 1,
            }"
            type="text"
            name="monto a facturar"
            label="Monto a facturar"
            maxlength="12"
            placeholder="100.00"
            prepend="$"
          />
        </b-col>
        <!-- #endregion::Count to be invoiced input -->
      </div>
    </b-collapse>
  </b-card>
</template>

<script>
// #region Imports
import { mapGetters, mapActions } from 'vuex'
import {
  required, decimal, notalpha, minValue, negative,
} from '@validations'
import {
  BCol, BRow, BCard, BButton, BCollapse, BFormRadio, BFormGroup,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'

import TextInputWithValidation from '@/components/forms/TextInputWithValidation.vue'
// #endregion

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCollapse,
    BFormRadio,
    BFormGroup,
    TextInputWithValidation,
  },
  data() {
    return {
      visible: false,
      iconToggle: 'ChevronDownIcon',
      collapseItemId: 'collapse-count-to-invoice-data',
      /**
       * Validations
       */
      decimal,
      required,
      notalpha,
      minValue,
      negative,
    }
  },
  computed: {
    ...mapGetters({
      getOrder: 'orders/getOrder',
      getCountToInvoice: 'orders/getCountToInvoice',
      getInvoiceRequired: 'orders/getInvoiceRequired',
    }),
    /**
     * Información de la orden
     */
    order: {
      get() { return this.getOrder },
    },
    /**
     * Monto a facturar
     */
    countToInvoice: {
      get() { return this.getCountToInvoice },
      set(value) { this.setCountToInvoice(value) },
    },
    /**
     * Indica si la orden requiere factura
     */
    invoiceRequired: {
      get() { return this.getInvoiceRequired },
      set(value) { this.setInvoiceRequired(value) },
    },
  },
  watch: {
    /**
     * Si se recarga la orden se actualiza la información del monto a facturar
     */
    order() {
      this.loadCountToInvoiceData()
    },
    invoiceRequired(value) {
      if (value === 'No') {
        this.countToInvoice = 0
      }
    },
  },
  created() {
    this.collapseItemId = uuidv4()
    this.loadCountToInvoiceData()
  },
  methods: {
    ...mapActions({
      setCountToInvoice: 'orders/setCountToInvoice',
      setInvoiceRequired: 'orders/setInvoiceRequired',
    }),
    collapse() {
      this.$root.$emit('bv::toggle::collapse', this.collapseItemId)
      this.visible = !this.visible
      this.setToggleIcon()
    },
    setToggleIcon() {
      this.iconToggle = this.visible ? 'ChevronUpIcon' : 'ChevronDownIcon'
    },

    /**
    * Información del monto a facturar
    *
    * @summary Carga la información del monto a facturar
    */
    loadCountToInvoiceData() {
      this.invoiceRequired = this.order.order.invoice_required
      this.countToInvoice = this.order.order.AmountInvoiced || ''
    },
  },
}
</script>
