<template>
  <div class="modal-request-transfer">
    <b-button
      v-b-modal="'modal-request-transfer'"
      variant="principal-btn"
      class="my-1 my-md-0 w-100 principal-btn"
    >
      <span class="align-middle">Solicitud de traspaso</span>
    </b-button>
    <validation-observer ref="orderRequestTransferForm">
      <b-form @submit="$event.preventDefault()">
        <!-- The modal -->
        <b-modal
          id="modal-request-transfer"
          ref="modal-request-transfer"
          size="md"
          :title="title"
        >
          <p>Por favor, indica el destino del traspaso</p>
          <div class="row">
            <div class="col-md-3">
              <p><b>Origen</b></p>
              {{ orderlocation }}
            </div>
            <div class="col-md-5 align-self-center">
              <i class="gg-arrow-long-right" />
            </div>
            <div class="col-md-4">
              <p><b>Destino</b></p>
              <validation-provider
                #default="{ errors }"
                name="comentario"
                vid="Comentario"
              >
                <SelectWithValidation
                  v-model="selectedLocation"
                  vid="selectedLocation"
                  name="destino"
                  property="text"
                  :options="filteredLocations"
                  rules="required"
                  placeholder="Selecciona el destino"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>
          <!-- #region::Footer -->
          <template #modal-footer>
            <b-button
              variant="delete-btn"
              class="delete-btn"
              @click="hideModal"
            >
              Cancelar
            </b-button>
            <b-button
              variant="principal-btn"
              class="principal-btn"
              @click="onRequestTransfer"
            >
              Solicitar traspaso
            </b-button>
          </template>
        <!-- #endregion::Footer -->
        </b-modal>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { required } from '@validations'
import { ValidationObserver, ValidationProvider, localize } from 'vee-validate'
import {
  BModal, BButton, BForm,
} from 'bootstrap-vue'
import SelectWithValidation from '@/components/forms/SelectWithValidation.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import getError from '@/helpers/ErrorsHandler'

export default {
  name: 'OrderRequestTransferModal',
  components: {
    BModal,
    BButton,
    BForm,
    SelectWithValidation,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    title: {
      type: String,
      default: 'Solicitud de traspaso',
    },
    orderId: {
      type: String,
      default: null,
    },
    orderlocation: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selectedLocation: null,
      savingLocation: false,

      // * 30/03/2023 - TAG: Validations
      required,
    }
  },
  computed: {
    ...mapGetters({
      getLocations: 'orders/getLocations',
    }),
    locations: {
      get() { return this.getLocations },
    },
    filteredLocations() {
      if (this.orderlocation) {
        return this.locations.filter(location => location.text !== this.orderlocation)
      }

      return this.locations
    },
  },
  async created() {
    localize('es')
  },
  // created() {
  //   this.location = this.orderlocation
  // },
  methods: {
    ...mapActions({
      requestTransfer: 'orders/requestTransfer',
    }),
    async onRequestTransfer() {
      const success = await this.$refs.orderRequestTransferForm.validate()
      if (success) {
        this.$swal({
          title: 'Espere por favor',
          allowOutsideClick: false,
        })

        this.$swal.showLoading()

        const formData = new FormData()
        formData.append('id', this.orderId)
        formData.append('destiny', this.selectedLocation.text)

        try {
          this.savingLocation = true
          await this.requestTransfer(formData)

          this.showToast(
            'Traspaso solicitado',
            'El traspaso ha sido solicitado correctamente.',
            'success',
          )

          this.hideModal()
          this.$emit('reload-order')
        } catch (error) {
          this.showToast('Error de validación', getError(error), 'danger')
        } finally {
          this.savingLocation = false
          this.$swal.close()
        }
      }
    },
    hideModal() {
      this.$refs['modal-request-transfer'].hide()
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>

  <style lang="scss" >

  .modal{
    #modal-tall___BV_modal_content_{
      padding: 16px!important;
    }
  }
  .space {
    width: 1px;
    height: 30px;
    background-color: rgb(146, 146, 146);
    margin-top: 10px;
  }
  .request-transfer{
    &:last-child{
      .space{
        display: none;
      }
    }
  }
  .gg-arrow-long-right {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs,1));
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    box-shadow: inset 0 0 0 2px;
    width: 100%;
    height: 6px
}
.gg-arrow-long-right::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 10px;
    height: 10px;
    border-top: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg);
    right: 0;
    bottom: -4px
}
  </style>
