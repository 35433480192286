<template>
  <div v-if="comments.length > 0">
    <!-- #region::Title -->
    <b-form-row>
      <b-col
        md="12"
        class="mb-1 mt-1"
      >
        <h3>Comentarios</h3>
      </b-col>
    </b-form-row>
    <!-- #endregion::Title -->
    <b-card
      no-body
      class="border border-dark shadow-none"
    >
      <b-card-body>
        <b-card-text
          v-for="comment in comments"
          :key="comment.cancelled_at"
          class="d-flex justify-content-right"
        >
          <div>
            <avatar
              :username="comment.cancelled_by"
              background-color="#0053D3"
              color="#fff"
            />
          </div>
          <div class="ml-2">
            <h4 class="font-weight-bolder">
              {{ comment.cancelled_by }}
              <span v-if="comment.ID">
                · ID {{ comment.ID }}
              </span>
            </h4>
            <h6>{{ comment.comment }}</h6>
            <small class="text-muted">{{ comment.cancelled_at | moment("DD/MM/YYYY") }}</small>
          </div>
        </b-card-text>
      </b-card-body>
    </b-card>
    <!-- #region begin::Pagination & items per list -->
    <BasicPaginator
      ref="basic-paginator"
      class="mt-2"
      :total-rows="totalComments"
      :callback="handleChangePagination"
      :can-apply-global-pagination="false"
      :default-per-page="perPage"
    />
  <!-- #endregion end::Pagination & items per list -->
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import {
  BCard, BCardText, BCardBody, BFormRow, BCol,
} from 'bootstrap-vue'

import Avatar from 'vue-avatar'
import BasicPaginator from '@/components/tables/BasicPaginator.vue'

export default {
  components: {
    BCard,
    Avatar,
    BCardText,
    BCardBody,
    BasicPaginator,
    BFormRow,
    BCol,
  },
  props: {
    orderId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      comments: [],
      totalComments: 0,
      isLoadingComments: false,
      perPage: 5,
      currentPage: 1,
    }
  },
  async created() {
    await this.loadCommentsList()
  },
  methods: {
    ...mapActions({
      loadComments: 'orders/loadComments',
    }),
    /**
    * Evento de paginación
    *
    * @summary Evento del componente de paginación. Establece la página actual y la cantidad
    * de registros por página
    * @param {number} currentPage - Página actual
    * @param {number} perPage - Elementos a mostrar por página
    */
    async handleChangePagination(currentPage, perPage) {
      this.perPage = perPage
      this.currentPage = currentPage
      await this.loadCommentsList()
    },
    /**
     * @summary Carga la lista de comentarios
     */
    async loadCommentsList() {
      this.$swal({
        title: 'Cargando...',
        allowOutsideClick: false,
      })
      this.$swal.showLoading()
      this.isLoadingComments = true
      const response = await this.loadComments(this.loadParams())
      this.comments = response.data.data.data
      this.totalComments = response.data.data.total

      this.isLoadingComments = false
      this.$swal.close()
    },
    /**
     * @summary Carga los parámetros para la petición de comentarios
     */
    loadParams() {
      return {
        orderId: this.orderId,
        perPage: this.perPage,
        currentPage: this.currentPage,
      }
    },
  },
}
</script>
