<template>
  <b-card class="blue-card">
    <b-card-text>
      <b-row>

        <!-- #region::Column for products count -->
        <b-col>
          <InformationBasicCard
            title="Productos"
            :tags="[{ value: order.order.Qty, type: 'string' }]"
          />
        </b-col>
        <!-- #endregion::Column for products count -->

        <!-- #region::Column for delivery type -->
        <b-col v-if="$ability.can('read delivery_type', 'Order')">
          <InformationBasicCard
            title="Tipo de entrega"
            :tags="[{ value: order.order.DeliveryType, type: 'string' }]"
          />
        </b-col>
        <!-- #endregion::Column for delivery type -->

        <!-- #region::Column for invoice required -->
        <b-col v-if="$ability.can('read invoice_request', 'Order')">
          <InformationBasicCard
            title="Solicitud de factura"
            :tags="[{ value: order.order.invoice_required, type: 'string' }]"
          />
        </b-col>
        <!-- #endregion::Column for invoice required -->

        <!-- #region::Column for required delivery date -->
        <b-col
          v-if="$ability.can('read delivery_date', 'Order')"
          cols="2"
        >
          <InformationBasicCard
            v-if="canShowRequiredDeliveryDate"
            title="Fecha de entrega requerida"
            :tags="[{ value: order.order.RequiredDeliveryDate, type: 'date' }]"
          />
          <div
            v-else
            class="my-1"
          >
            <h5 class="font-weight-bolder">
              Fecha de entrega requerida
            </h5>
            <div class="d-flex justify-content-between">
              <DatePickerWithValidation
                v-model="requiredArriveDate"
                class="w-100"
                vid="arriveDate"
                name="fecha de entrega requerida"
                placeholder="Selecciona la fecha de entrega requerida"
                :show-label="false"
              />
              <b-form-checkbox
                v-model="approvedArriveDate"
                class="ml-1 align-self-center"
              />
            </div>
          </div>
        </b-col>
        <!-- #endregion::Column for required delivery date -->

        <!-- #region::Column for approved date -->
        <b-col v-if="$ability.can('read approved_date', 'Order')">
          <InformationBasicCard
            title="Fecha aprobada"
            :tags="[{ value: order.order.ConfirmedDeliveryDate, type: 'date' }]"
          />
        </b-col>
        <!-- #endregion::Column for approved date -->

        <!-- #region::Column for location -->
        <b-col v-if="$ability.can('read location', 'Order')">
          <InformationBasicCard
            title="Ubicación"
            :tags="[{ value: order.order.location, type: 'string' }]"
          />
        </b-col>
        <!-- #endregion::Column for location -->

        <!-- #region::Column for creator -->
        <b-col v-if="canShowCreatedBy()">
          <InformationBasicCard
            title="Creado por"
            :tags="[{ value: order.order.created_by, type: 'date' }]"
          />
        </b-col>
        <!-- #endregion::Column for creator -->

      </b-row>
    </b-card-text>
  </b-card>
</template>

<script>
// #region Imports
import { mapGetters, mapActions } from 'vuex'
import {
  BRow, BCol, BCard, BCardText, BFormCheckbox,
} from 'bootstrap-vue'

import InformationBasicCard from '@/components/cards/InformationBasicCard.vue'
import DatePickerWithValidation from '@/components/forms/DatePickerWithValidation.vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import getError from '@/helpers/ErrorsHandler'
// #endregion

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BFormCheckbox,
    InformationBasicCard,
    DatePickerWithValidation,
  },
  data() {
    return {
      requiredArriveDate: '',
      approvedArriveDate: false,
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    ...mapGetters({
      getOrder: 'orders/getOrder',
    }),
    order: {
      get() { return this.getOrder },
    },
    canShowRequiredDeliveryDate() {
      return this.order.order.ConfirmedDeliveryDate
          || this.userData.Role !== 'Coordinador de Almacen'
    },
  },
  watch: {
    approvedArriveDate() {
      if (this.approvedArriveDate) {
        this.updateOrderApprovedDate()
      }
    },
  },
  created() {
    this.requiredArriveDate = this.order.order.RequiredDeliveryDate
  },
  methods: {
    ...mapActions({
      confirmDeliveryDate: 'orders/confirmDeliveryDate',
    }),
    canShowCreatedBy() {
      const role = JSON.parse(localStorage.userData)?.Role
      return role === 'Administrador'
          || role === 'Coordinador de Almacen'
          || role === 'Encargado de Facturacion'
    },
    async updateOrderApprovedDate() {
      try {
        this.$swal({
          title: 'Espere por favor',
          allowOutsideClick: false,
        })

        this.$swal.showLoading()

        const formData = new FormData()
        formData.append('date', this.requiredArriveDate)

        // eslint-disable-next-line no-restricted-syntax
        for (const pair of formData.entries()) {
          console.log(`${pair[0]}, ${pair[1]}`)
        }
        await this.confirmDeliveryDate({ id: this.order.order.ID, formData })

        this.showToast(
          'Fecha aprobada',
          'La fecha de entrega requerida ha sido aprobada correctamente.',
          'success',
        )

        this.$emit('reload-order')
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.$swal.close()
      }
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
b-card {
  background-color: blue
}
</style>
