<template>
  <div>
    <b-row>
      <b-col
        v-if="$ability.can('read customer_data', 'Order')"
        md="4"
      >
        <b-card class="border border-dark shadow-none">
          <div>
            <b-row>
              <b-col class="align-self-end">
                <h4 class="font-weight-bolder">
                  Datos del cliente
                </h4>
              </b-col>
              <b-button
                :variant="visible ? 'flat-primary' : 'flat-secondary'"
                class="btn-icon rounded-circle mr-1"
                @click="collapse"
              >
                <feather-icon :icon="iconToggle" />
              </b-button>
            </b-row>
          </div>

          <b-collapse
            :id="collapseItemId"
            v-model="visible"
            role="tabpanel"
          >
            <div>
              <hr>
              <h4 class="font-weight-bolder">
                {{ `${order.client.name} ${order.client.last_name || ''}` }}
              </h4>
            </div>
            <div class="text-primary">
              {{ order.client.email }}
            </div>
            <div>
              {{ order.client.phone }}
            </div>
            <InformationBasicCard
              v-if="order.datos_de_facturacion.razon_social"
              title="Razón social"
              :tags="[{ value:order.datos_de_facturacion.razon_social, type: 'string' }]"
            />
          </b-collapse>
        </b-card>
      </b-col>
      <b-col
        v-if="$ability.can('read delivery_address', 'Order')"
        md="4"
      >
        <ShipmentAddress :order="order" />
      </b-col>
      <b-col
        v-if="$ability.can('read billing_address', 'Order')"
        md="4"
      >
        <InvoiceAddress :order="order" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BRow, BCol, BCard, BButton, BCollapse,
} from 'bootstrap-vue'

// import InvoiceData from '@/modules/trade/orders/components/form/InvoiceData.vue'
import InformationBasicCard from '@/components/cards/InformationBasicCard.vue'
import InvoiceAddress from '@/modules/trade/orders/components/form/InvoiceAddress.vue'
import ShipmentAddress from '@/modules/trade/orders/components/form/ShipmentAddress.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCollapse,
    // InvoiceData,
    InvoiceAddress,
    ShipmentAddress,
    InformationBasicCard,
  },
  data() {
    return {
      visible: false,
      collapseItemId: '5',
      iconToggle: 'ChevronDownIcon',
    }
  },
  computed: {
    ...mapGetters({
      getOrder: 'orders/getOrder',
    }),
    order: {
      get() { return this.getOrder },
    },
  },
  methods: {
    collapse() {
      this.$root.$emit('bv::toggle::collapse', this.collapseItemId)
      this.visible = !this.visible
      this.setToggleIcon()
    },
    setToggleIcon() {
      this.iconToggle = this.visible ? 'ChevronUpIcon' : 'ChevronDownIcon'
    },
  },
}
</script>
