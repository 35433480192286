<template>
  <b-card class="border border-dark shadow-none">
    <div>
      <b-row>

        <!-- #region::Title -->
        <b-col class="align-self-end">
          <h4 class="font-weight-bolder">
            Constancia de situación fiscal
          </h4>
        </b-col>
        <!-- #endregion::Title -->

        <!-- #region begin::Collapse card button -->
        <b-button
          :variant="visible ? 'flat-primary' : 'flat-secondary'"
          class="btn-icon rounded-circle mr-1"
          @click="collapse"
        >
          <feather-icon :icon="iconToggle" />
        </b-button>
        <!-- #endregion end::Collapse card button -->

      </b-row>
    </div>

    <!-- #region::Downloading file options -->
    <b-collapse
      :id="collapseItemId"
      v-model="visible"
      role="tabpanel"
    >
      <hr>
      <div class="my-1">
        <h5 class="font-weight-bolder">
          {{ fileName }}
        </h5>
        <div class="d-flex">

          <!-- #region::Download button -->
          <div style="min-width: 70px;">
            <span
              v-if="!isDownloadingFile"
              class="align-middle font-weight-bolder text-primary cursor-pointer"
              @click="onDownloadFile"
            >Descargar</span>
            <b-spinner
              v-else
              class="font-weight-bolder text-primary"
              small
            />
          </div>
          <!-- #endregion::Download button -->

          <!-- #region::Update button -->
          <div
            v-if="canManageFile"
            class="ml-1"
          >
            <span
              class="align-middle font-weight-bolder text-primary cursor-pointer"
              @click="$refs.uploadTaxSituationProofModal.showModal({ id: idModel, type: 'order', update: 1 })"
            >Actualizar</span>
          </div>
          <!-- #endregion::Update button -->

          <!-- #region::Delete button -->
          <div
            v-if="canManageFile"
            class="ml-auto"
          >
            <span
              v-if="!isDeletingFile"
              class="align-middle font-weight-bolder text-danger cursor-pointer"
              @click="onDeleteFile"
            >Eliminar</span>
            <b-spinner
              v-else
              class="font-weight-bolder text-danger"
              small
            />
          </div>
          <!-- #endregion::Delete button -->

        </div>
      </div>
    </b-collapse>
    <!-- #endregion::Downloading file options -->

    <!-- #region::Modal for making rejected quote comment -->
    <UploadTaxSituationProofModal
      v-if="canManageFile"
      ref="uploadTaxSituationProofModal"
      @reload-order="$emit('reload-order')"
    />
    <!-- #endregion::Modal for making rejected quote comment -->

  </b-card>
</template>

<script>
import { mapActions } from 'vuex'
import {
  BCard, BCollapse, BRow, BCol, BButton, BSpinner,
} from 'bootstrap-vue'

import UploadTaxSituationProofModal from '@/modules/trade/quotes/components/modals/UploadTaxSituationProofModal.vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import getError from '@/helpers/ErrorsHandler'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BSpinner,
    BCollapse,
    UploadTaxSituationProofModal,
  },
  props: {
    idModel: {
      type: String,
      required: true,
    },
    fileUrl: {
      type: String,
      required: true,
    },
    fileName: {
      type: String,
      required: true,
    },
    idModelType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
      collapseItemId: '6',
      iconToggle: 'ChevronDownIcon',
      isDownloadingFile: false,
      isDeletingFile: false,
    }
  },
  computed: {
    /**
     * Obtiene la extensión del archivo
     */
    fileExtension() {
      return this.fileName.split('.').pop()
    },
    /**
     * Determina si el usuario puede gestionar el archivo (actualizar o eliminar)
     */
    canManageFile() {
      return this.$ability.can('save tax situation', 'Order') && this.idModelType === 'orderID'
    },
  },
  methods: {
    ...mapActions({
      deleteTaxSituationProof: 'quotes/deleteTaxSituationProof',
      downloadTaxSituationProof: 'quotes/downloadTaxSituationProof',
    }),
    /**
     * Colapsa el card de total de la cotización
     */
    collapse() {
      this.$root.$emit('bv::toggle::collapse', this.collapseItemId)
      this.visible = !this.visible
      this.setToggleIcon()
    },

    /**
     * Cambia el icono de la flecha de colapsar
     */
    setToggleIcon() {
      this.iconToggle = this.visible ? 'ChevronUpIcon' : 'ChevronDownIcon'
    },

    /**
     * Descarga el archivo de la constancia de situación fiscal
     * @summary Activa la descarga del archivo de la constancia de situación fiscal
     * con el nombre y extensión del archivo
     * @exception Error de validación
     */
    async onDownloadFile() {
      try {
        this.isDownloadingFile = true
        await this.downloadTaxSituationProof({
          idModel: `${this.idModelType}=${this.idModel}`,
          fileName: this.fileName,
          fileExtension: this.fileExtension,
        })
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.isDownloadingFile = false
      }
    },

    /**
     * Elimina el archivo de la constancia de situación fiscal
     * @summary Activa la eliminación del archivo de la constancia de situación fiscal
     * @exception Error de validación
     */
    async onDeleteFile() {
      try {
        this.isDeletingFile = true
        await this.deleteTaxSituationProof(this.idModel)
        this.showToast('Archivo eliminado', 'El archivo se ha eliminado correctamente.', 'success')
        this.$emit('reload-order')
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.isDeletingFile = false
      }
    },

    /**
    * Mensaje de notificación
    * @summary Despliega un mensaje de notificación
    * @param {string} title - Título del mensaje de notificación
    * @param {string} text - Contenido del mensaje de notificación
    * @param {string} variant - Tipo de notificación
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
