<template>
  <div>
    <b-modal
      id="tax-situation-proof-modal"
      ref="tax-situation-proof-modal"
      centered
      size="lg"
      title="Constancia de situación fiscal"
      @hidden="onCloseModal"
    >

      <!-- #region::Modal content -->
      <b-card-text>
        <b-row>

          <!-- #region::Error label -->
          <b-col
            v-if="showError"
            md="12"
            class="mb-2"
          >
            <b-alert
              variant="danger"
              :show="true"
              class="mb-0"
            >
              <div class="alert-body">
                <feather-icon
                  icon="InfoIcon"
                  class="mr-50"
                />
                {{ error }}
              </div>
            </b-alert>
          </b-col>
          <!-- #endregion::Error label -->

          <!-- #region::Dropzone -->
          <b-col
            v-if="!file"
            md="12"
          >
            <b-img
              fluid
              :src="active
                ? require('@/assets/images/placeholders/file-dropzone-drop.svg')
                : require('@/assets/images/placeholders/file-dropzone.svg')"
              @dragenter.prevent="toggleActive"
              @dragleave.prevent="toggleActive"
              @dragover.prevent
              @drop.prevent="onDropFile"
              @click="onSelectFile"
            />
          </b-col>
          <!-- #endregion::Dropzone -->

          <!-- #region::File name and delete option -->
          <b-col v-if="file">
            <b-card>
              <b-card-text>
                <b-row>
                  <b-col
                    class="align-self-center"
                    md="6"
                  >
                    <span><feather-icon
                      class="mr-50"
                      icon="FileTextIcon"
                    />{{ file.name }}</span>
                  </b-col>

                  <b-col
                    cols="12"
                    md="auto"
                    class="ml-auto"
                  >
                    <b-button
                      variant="flat-secondary"
                      @click="onDeleteLoadedFile"
                    >
                      <feather-icon
                        icon="Trash2Icon"
                        class="mr-50"
                      />
                      <span class="align-middle">Eliminar</span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-card-text>
            </b-card>
          </b-col>
          <!-- #endregion::File name and delete option -->

        </b-row>
      </b-card-text>
      <!-- #endregion::Modal content -->

      <!-- #region::Footer -->
      <template #modal-footer>
        <b-button
          variant="delete-btn"
          class="delete-btn"
          @click="$refs['tax-situation-proof-modal'].hide()"
        >
          Cancelar
        </b-button>
        <b-button
          variant="principal-btn"
          class="principal-btn"
          @click="onUploadFile"
        >
          <span class="align-middle">Subir archivo</span>
        </b-button>
      </template>
    <!-- #endregion::Footer -->

    </b-modal>

    <input
      v-show="false"
      id="fileSelector"
      ref="fileSelector"
      type="file"
      accept="image/jpeg,image/jpg,image/png,application/pdf"
      @change="onSelectedFile($event)"
    >
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import {
  BRow, BCol, VBModal, BCardText, BButton, BImg, BCard, BAlert,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import getError from '@/helpers/ErrorsHandler'

const VALID_FILES_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf']

export default {
  components: {
    BImg,
    BRow,
    BCol,
    BCard,
    BAlert,
    BButton,
    BCardText,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      active: false,
      error: null,
      file: '',
      model: null,
    }
  },
  computed: {
    showError() {
      return this.error
    },
  },
  methods: {
    ...mapActions({
      uploadTaxSituationProof: 'quotes/uploadTaxSituationProof',
    }),
    /**
     * Evento al soltar el archivo
     * @resumen Valida el formato del archivo y lo procesa
     * @param {Event} event - Evento de soltar el archivo
     */
    onDropFile(event) {
      const file = event.dataTransfer.files[0]
      if (this.isValidFormatFile(file)) {
        this.processFile(file)
      }
    },

    /**
     * Muestra el selector de archivos
     */
    onSelectFile() {
      this.$refs.fileSelector.click()
    },

    /**
     * Evento al seleccionar el archivo
     * @resumen Valida el formato del archivo y lo procesa
     * @param {Event} event - Evento de seleccionar el archivo
     */
    onSelectedFile(event) {
      const file = event.target.files[0]
      if (this.isValidFormatFile(file)) {
        this.processFile(file)
      }
    },

    /**
     * Activa o desactiva el dropzone
     */
    toggleActive() {
      this.active = !this.active
    },

    /**
     * Valida el formato del archivo
     * @resumen Valida el formato del archivo y su tamaño
     * @param {File} file - Archivo a validar
     */
    isValidFormatFile(file) {
      if (!file.type || !VALID_FILES_TYPES.includes(file.type)) {
        this.error = 'El formato del archivo es incorrecto. Formato permitido para cargar: .pdf, .jpg, .jpeg, .png'
        this.onDeleteLoadedFile(false)
        return false
      }

      const sizeInMB = (file.size / (1024 * 1024)).toFixed(2)

      if (sizeInMB > 2) {
        this.error = 'El tamaño del archivo es demasiado grande. Tamaño permitido menor o igual a 2MB.'
        this.onDeleteLoadedFile(false)
        return false
      }

      return true
    },

    /**
     * Procesa el archivo
     * @param {File} file - Archivo a procesar
     */
    processFile(file) {
      this.active = false

      if (!file) {
        return
      }

      this.error = null
      this.file = file
    },

    /**
     * Muestra el modal
     * @param {string} model - Modelo de la cotización o la orden
     */
    showModal(model) {
      this.model = model
      this.$refs['tax-situation-proof-modal'].show()
    },

    /**
     * Oculta el modal
     */
    hideModal() {
      this.$refs['tax-situation-proof-modal'].hide()

      // Valida que el tipo de modelo sea orden para refrescar el detalle
      // siempre y cuando se haya cargado una imagen
      if (this.model.type === 'order') {
        this.$emit('reload-order')
      }
    },

    /**
     * Elimina el archivo cargado
     */
    onDeleteLoadedFile(hideError = true) {
      this.file = null

      if (hideError) {
        this.error = null
      }

      this.active = false
      document.getElementById('fileSelector').value = ''
    },

    /**
     * Sube el archivo
     */
    async onUploadFile() {
      if (this.file) {
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()

        try {
          const formData = new FormData()

          // Identificador del modelo (cotización, orden)
          formData.append(this.model.type === 'quote' ? 'quoteID' : 'orderID', this.model.id)
          formData.append('files[]', this.file)

          // Verifica si es actualización del modelo
          formData.append('update', this.model.update || 0)

          // eslint-disable-next-line no-restricted-syntax
          for (const pair of formData.entries()) {
            console.log(`${pair[0]}, ${pair[1]}`)
          }

          // Action to upload file to server
          await this.uploadTaxSituationProof(formData)
          this.showToast('Archivo cargado', 'El archivo se ha cargado correctamente.', 'success')
        } catch (error) {
          this.showToast('Error de validación', getError(error), 'danger')
        } finally {
          this.$swal.close()
          // Oculta el modal y recarga la orden
          this.hideModal()
        }
      } else {
        this.showToast(
          'Archivo no cargado',
          'Es necesario agregar un archivo para poder continuar.',
          'warning',
        )
      }
    },

    /**
     * Evento al cerrar el modal
     */
    onCloseModal() {
      // Limpia el archivo cargado
      this.onDeleteLoadedFile()

      // Valida que el tipo de modelo sea cotización para refrescar el detalle
      // siempre y cuando se cierre el modal
      if (this.model.type === 'quote') {
        this.$emit('reload-quote')
      }
    },

    /**
    * Mensaje de notificación
    * @summary Despliega un mensaje de notificación
    * @param {string} title - Título del mensaje de notificación
    * @param {string} text - Contenido del mensaje de notificación
    * @param {string} variant - Tipo de notificación
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
