<template>
  <div>
    <b-row>

      <!-- #region::Title -->
      <b-col md="12">
        <div class="d-flex justify-content-between">
          <h3 class="font-weight-bolder">
            Historial de pagos
          </h3>
          <b-button
            v-if="$ability.can('send payment_reminder', 'Order') && canManageOrders"
            variant="flat-primary"
            :disabled="loadingState"
            @click="onSendPaymentReminder"
          >
            <b-spinner
              v-if="loadingState"
              class="mr-50"
              small
            />
            <feather-icon
              v-else
              icon="MailIcon"
              class="mr-50"
            />
            <span class="align-middle">Enviar recordatorio de pago</span>
          </b-button>
        </div>
      </b-col>
      <!-- #endregion::Title -->

      <b-col>
        <IconDetailsCard :card="percentagePaidCard" />
      </b-col>
      <b-col>
        <IconDetailsCard :card="paymentStatusCard" />
      </b-col>
      <b-col v-if="order.saldo_a_favor !== undefined">
        <IconDetailsCard :card="positiveBalanceCard" />
      </b-col>

      <!-- #region::Used positive balance card -->
      <b-col>
        <IconDetailsCard :card="usedPositiveBalanceCard">

          <template slot="subtext">
            <h5 class="text-muted align-self-end">
              ({{ order.saldo_a_favor_restante | currency }})
            </h5>
          </template>

          <!-- #region::Add pieces to circuit button -->
          <template slot="action">
            <b-button
              size="sm"
              variant="flat-primary align-self-center"
              class="mt-0"
              @click="$refs.orderUsedCreditBalanceModal.showModal()"
            >
              <span>Ver detalles</span>
            </b-button>
          </template>
          <!-- #endregion::Add pieces to circuit button -->
        </IconDetailsCard>
      </b-col>
      <!-- #endregion::Used positive balance card -->

      <b-col md="12">
        <OrderPaymentsHitoryList
          :can-manage-orders="canManageOrders"
          @reload-order="$emit('reload-order')"
        />
      </b-col>

      <b-col
        v-if="$ability.can('register payment', 'Order')"
        md="12"
      >
        <b-button
          variant="flat-primary"
          @click="onRegisterPayment"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span class="align-middle">Nuevo pago</span>
        </b-button>
      </b-col>
    </b-row>

    <OrderPaymentModal
      v-if="$ability.can('register payment', 'Order')"
      ref="orderPaymentModal"
      @reload-order="$emit('reload-order')"
    />

    <OrderUsedCreditBalanceModal
      v-if="existUsedCreditBalance"
      ref="orderUsedCreditBalanceModal"
      :model-id="order.order.IdOrder"
      :model-type="'order'"
      :payer-name="order.order.created_by"
      :used-credit-balance="order.saldo_a_favor_usado"
      :remainig-credit-balance="order.saldo_a_favor_restante"
    />
  </div>
</template>

<script>
// #region Imports
import { mapGetters, mapActions } from 'vuex'
import {
  BCol, BRow, BButton, BSpinner,
} from 'bootstrap-vue'

import IconDetailsCard from '@/components/cards/IconDetailsCard.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import OrderPaymentModal from '@/modules/trade/orders/components/modals/OrderPaymentModal.vue'
import OrderPaymentsHitoryList from '@/modules/trade/orders/components/lists/OrderPaymentsHitoryList.vue'
import OrderUsedCreditBalanceModal from '@/modules/trade/orders/components/modals/OrderUsedCreditBalanceModal.vue'

import getError from '@/helpers/ErrorsHandler'
import validateCurrency from '@/helpers/CurrencyFormat'
// #endregion

export default {
  components: {
    BCol,
    BRow,
    BButton,
    BSpinner,
    IconDetailsCard,
    OrderPaymentModal,
    OrderPaymentsHitoryList,
    OrderUsedCreditBalanceModal,
  },
  props: {
    canManageOrders: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loadingState: false,
    }
  },
  computed: {
    ...mapGetters({
      getOrder: 'orders/getOrder',
    }),
    order: {
      get() { return this.getOrder },
    },
    /**
     * Información de la tarjeta de estatus de pago
     */
    paymentStatusCard() {
      return {
        icon: 'ClockIcon',
        title: 'Estatus del pago',
        text: this.order.estatus_de_pago,
        styles: ['blue-style'],
      }
    },
    /**
     * Información de la tarjeta de porcentaje pagado
     */
    percentagePaidCard() {
      return {
        icon: 'ClockIcon',
        title: 'Porcentaje pagado',
        text: `${this.order.porcentaje_pagado}%`,
      }
    },
    /**
     * Información de la tarjeta de saldo a favor
     */
    positiveBalanceCard() {
      return {
        icon: 'ClockIcon',
        title: 'Saldo a favor',
        text: validateCurrency(this.order.saldo_a_favor),
      }
    },
    /**
     * Información de la tarjeta de saldo a favor usado
     */
    usedPositiveBalanceCard() {
      return {
        icon: 'ClockIcon',
        title: 'Saldo a favor usado',
        text: validateCurrency(this.order.saldo_a_favor_usado),
      }
    },
    existUsedCreditBalance() {
      return this.order.saldo_a_favor_usado !== null
    },
  },
  methods: {
    ...mapActions({
      sendPaymentReminder: 'orders/sendPaymentReminder',
    }),
    onRegisterPayment() {
      this.$refs.orderPaymentModal.showModal(this.order.pagos_registrados[0].quotation_id)
    },
    async onSendPaymentReminder() {
      try {
        this.loadingState = true
        await this.sendPaymentReminder(this.order.order.ID)

        this.showToast(
          'Recordatorio de pago enviado',
          'El recordatorio de pago ha sido enviado correctamente.',
          'success',
        )
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.loadingState = false
      }
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
