<template>
  <div>
    <b-skeleton
      class="mt-1"
      animation="wave"
      width="100%"
      height="170px"
    />
    <b-row>
      <b-col md="4">
        <b-skeleton
          class="mt-1"
          animation="wave"
          width="100%"
          height="120px"
        />
      </b-col>
      <b-col md="4">
        <b-skeleton
          class="mt-1"
          animation="wave"
          width="100%"
          height="120px"
        />
      </b-col>
      <b-col md="4">
        <b-skeleton
          class="mt-1"
          animation="wave"
          width="100%"
          height="120px"
        />
      </b-col>
    </b-row>
    <b-skeleton
      class="mt-1"
      animation="wave"
      width="100%"
      height="100px"
    />
    <b-skeleton
      class="mt-2"
      animation="wave"
      width="100%"
      height="260px"
    />
  </div>
</template>

<script>
import {
  BSkeleton, BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BSkeleton,
  },
}
</script>
