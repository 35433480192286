<template>
  <b-modal
    id="partial-delivery-modal"
    ref="partial-delivery-modal"
    title="Entrega parcial"
    centered
    size="xl"
    @hidden="onCloseModal"
  >
    <b-card-text>

      <!-- #region::Products list -->
      <b-row>

        <b-col md="12">
          Selecciona los productos que se entregarán
        </b-col>

        <!-- #region begin::Searcher -->
        <b-col
          class="my-2"
          md="4"
        >
          <BasicSearcher
            :callback="handleSearch"
            placeholder="Buscar productos y circuitos"
          />
        </b-col>
        <!-- #endregion end::Searcher -->

        <!-- #region::Products list -->
        <b-col md="12">
          <OrderProductsDetails :selectable="true" />
        </b-col>
        <!-- #endregion::Products list -->

        <b-col md="12">
          <OrderCircuitsDetails
            :selectable="true"
            class="mt-2"
          />
        </b-col>

      </b-row>
      <!-- #endregion::Products list -->

    </b-card-text>

    <!-- #region::Footer -->
    <template #modal-footer>
      <b-button
        variant="delete-btn"
        class="delete-btn"
        @click="hideModal"
      >
        Cancelar
      </b-button>
      <b-button
        variant="principal-btn"
        class="principal-btn"
        :disabled="makingPiecesOutputs"
        @click="onAddToPartialDeliveryList"
      >
        <b-spinner
          v-if="makingPiecesOutputs"
          class="mr-50"
          small
        />
        <span class="align-middle">Seleccionar productos</span>
      </b-button>
    </template>
    <!-- #endregion::Footer -->

  </b-modal>
</template>

<script>
// #region Imports
import { mapGetters, mapActions } from 'vuex'
import {
  BRow, BCol, VBModal, BCardText, BButton, BSpinner,
} from 'bootstrap-vue'

import BasicSearcher from '@/components/forms/BasicSearcher.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import getError from '@/helpers/ErrorsHandler'
// #endregion

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BSpinner,
    BCardText,
    BasicSearcher,
    OrderCircuitsDetails: () => import('@/modules/trade/orders/components/OrderCircuitsDetails.vue'),
    OrderProductsDetails: () => import('@/modules/trade/orders/components/OrderProductsDetails.vue'),
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    orderId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      makingPiecesOutputs: false,
    }
  },
  computed: {
    ...mapGetters({
      getOrder: 'orders/getOrder',
      getDetailProducts: 'orders/getDetailProducts',
      getDetailCircuits: 'orders/getDetailCircuits',
      getDetailProductsPieces: 'orders/getDetailProductsPieces',
      getPartialDeliveryProvitionalCircuits: 'orders/getPartialDeliveryProvitionalCircuits',
      getPartialDeliveryProvitionalProductsPieces: 'orders/getPartialDeliveryProvitionalProductsPieces',

      // * 27/01/2023 - TAG: For validating selection products on modal delivery type
      getDetailCircuitsWithoutDeliveredState: 'orders/getDetailCircuitsWithoutDeliveredState',
      getDetailProductsPiecesWithoutDeliveredState: 'orders/getDetailProductsPiecesWithoutDeliveredState',
    }),
    order: {
      get() { return this.getOrder },
    },
    detailProducts: {
      get() { return this.getDetailProducts },
    },
    detailCircuits: {
      get() { return this.getDetailCircuits },
    },
    detailProductsPieces: {
      get() { return this.getDetailProductsPieces },
    },
    partialDeliveryProvitionalCircuits: {
      get() { return this.getPartialDeliveryProvitionalCircuits },
    },
    partialDeliveryProvitionalProductsPieces: {
      get() { return this.getPartialDeliveryProvitionalProductsPieces },
    },
    detailCircuitsWithoutDeliveredState: {
      get() { return this.getDetailCircuitsWithoutDeliveredState },
    },
    detailProductsPiecesWithoutDeliveredState: {
      get() { return this.getDetailProductsPiecesWithoutDeliveredState },
    },
  },
  methods: {
    ...mapActions({
      setSearchTerm: 'orders/setSearchTerm',
      makePiecesOutputs: 'orders/makePiecesOutputs',
      clearDeliveryState: 'orders/clearDeliveryState',
    }),
    showModal() {
      this.$refs['partial-delivery-modal'].show()
    },
    hideModal() {
      this.$refs['partial-delivery-modal'].hide()
    },
    async onAddToPartialDeliveryList() {
      const detailpiecesCount = this.detailProductsPieces.length
      const detailProductsPiecesWithoutDeliveredStateCount = this.detailProductsPiecesWithoutDeliveredState.length

      const detailCircuitsCount = this.detailCircuits.length
      const detailCircuitsWithoutDeliveredStateCount = this.detailCircuitsWithoutDeliveredState.length

      const deliveryPiecesCount = this.partialDeliveryProvitionalProductsPieces.length
      const deliveryCircuitsCount = this.partialDeliveryProvitionalCircuits.length

      let samePiecesCount = false
      let sameCircuitsCount = false

      if (this.order.order.current_status !== 'En suministro') {
        if (deliveryPiecesCount > 0 && (detailpiecesCount === deliveryPiecesCount)) {
          samePiecesCount = true
        }

        if (deliveryCircuitsCount > 0 && detailCircuitsCount === deliveryCircuitsCount) {
          sameCircuitsCount = true
        }

        if (samePiecesCount && deliveryCircuitsCount === 0 && detailCircuitsWithoutDeliveredStateCount === 0) {
          sameCircuitsCount = true
        }

        if (sameCircuitsCount && deliveryPiecesCount === 0 && detailProductsPiecesWithoutDeliveredStateCount === 0) {
          samePiecesCount = true
        }
      } else {
        if (deliveryPiecesCount > 0 && detailProductsPiecesWithoutDeliveredStateCount === deliveryPiecesCount) {
          samePiecesCount = true
        }

        if (deliveryCircuitsCount > 0 && detailCircuitsWithoutDeliveredStateCount === deliveryCircuitsCount) {
          sameCircuitsCount = true
        }

        if (samePiecesCount && deliveryCircuitsCount === 0 && detailCircuitsWithoutDeliveredStateCount === 0) {
          sameCircuitsCount = true
        }

        if (sameCircuitsCount && deliveryPiecesCount === 0 && detailProductsPiecesWithoutDeliveredStateCount === 0) {
          samePiecesCount = true
        }
      }

      if (samePiecesCount && sameCircuitsCount) {
        this.showToast(
          'Error de validación',
          'No se pueden seleccionar todos los productos para el tipo de entrega parcial.',
          'warning',
        )

        return
      }

      try {
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()

        this.makingPiecesOutputs = true
        const formData = new FormData()

        formData.append('delivery_type', 5)

        this.partialDeliveryProvitionalProductsPieces.forEach(piece => {
          formData.append('pieces[]', piece.IdQuotesCircuitsProductsPiece)
        })

        this.partialDeliveryProvitionalCircuits.forEach(circuit => {
          circuit.products.forEach(product => {
            product.pieces.forEach(piece => {
              formData.append('pieces[]', piece.IdQuotesCircuitsProductsPiece)
            })
          })
        })

        // eslint-disable-next-line no-restricted-syntax
        for (const pair of formData.entries()) {
          console.log(`${pair[0]}, ${pair[1]}`)
        }

        await this.makePiecesOutputs({ id: this.orderId, formData })
        this.$swal.close()

        this.showToast(
          'Tipo de entrega establecida',
          'El tipo de entrega de la orden ha sido establecido correctamente a entrega parcial.',
          'success',
        )

        this.$emit('reload-order')
        this.hideModal()
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
        this.$swal.close()
      } finally {
        this.makingPiecesOutputs = false
      }
    },
    handleSearch(searchTerm) {
      this.setSearchTerm(searchTerm)
    },
    onCloseModal() {
      this.detailCircuits.forEach(detailCircuit => {
        const circuit = detailCircuit
        circuit.selected = false
      })

      this.clearDeliveryState()
      this.$emit('on-close-modal')
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
