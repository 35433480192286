<template>
  <b-card class="border border-dark shadow-none">
    <div>
      <b-row>

        <!-- #region::Title -->
        <b-col class="align-self-end">
          <h4 class="font-weight-bolder">
            Datos de facturación
          </h4>
        </b-col>
        <!-- #endregion::Title -->

        <!-- #region::Collapsable options -->
        <b-col
          cols="12"
          md="auto"
          class="ml-auto"
        >

          <!-- #region begin::Collapse card button -->
          <b-button
            :variant="visible ? 'flat-primary' : 'flat-secondary'"
            class="btn-icon rounded-circle ml-1"
            @click="collapse"
          >
            <feather-icon :icon="iconToggle" />
          </b-button>
          <!-- #endregion end::Collapse card button -->

        </b-col>
        <!-- #endregion::Collapsable options -->

      </b-row>
    </div>

    <b-collapse
      :id="collapseItemId"
      v-model="visible"
      role="tabpanel"
    >
      <div>
        <hr>
        <InformationBasicCard
          title="Uso de CFDI"
          :tags="[{ value: order.datos_de_facturacion.uso_de_cfdi, type: 'string' }]"
        />
        <InformationBasicCard
          title="RFC"
          :tags="[{ value: order.datos_de_facturacion.rfc, type: 'string' }]"
        />
      </div>
    </b-collapse>

  </b-card>
</template>

<script>
// #region Imports
import {
  BCol, BButton, BCard, BRow, BCollapse,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'

import InformationBasicCard from '@/components/cards/InformationBasicCard.vue'
// #endregion

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCollapse,
    InformationBasicCard,
  },
  props: {
    order: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      visible: false,
      collapseItemId: '1',
      iconToggle: 'ChevronDownIcon',
    }
  },
  created() {
    this.collapseItemId = uuidv4()
  },
  methods: {
    collapse() {
      this.$root.$emit('bv::toggle::collapse', this.collapseItemId)
      this.visible = !this.visible
      this.setToggleIcon()
    },
    setToggleIcon() {
      this.iconToggle = this.visible ? 'ChevronUpIcon' : 'ChevronDownIcon'
    },
  },
}
</script>
