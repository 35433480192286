<template>
  <b-card class="border border-dark shadow-none">
    <div>
      <b-row>

        <!-- #region::Title -->
        <b-col class="align-self-end">
          <h4 class="font-weight-bolder">
            Constancia de situación fiscal
          </h4>
        </b-col>
        <!-- #endregion::Title -->

        <!-- #region begin::Collapse card button -->
        <b-button
          :variant="visible ? 'flat-primary' : 'flat-secondary'"
          class="btn-icon rounded-circle mr-1"
          @click="collapse"
        >
          <feather-icon :icon="iconToggle" />
        </b-button>
        <!-- #endregion end::Collapse card button -->

      </b-row>
    </div>

    <!-- #region::Downloading file options -->
    <b-collapse
      :id="collapseItemId"
      v-model="visible"
      role="tabpanel"
    >
      <hr>
      <b-row>

        <!-- #region::Preview icon -->
        <b-col
          cols="12"
          class="text-center"
        >
          <p>
            <feather-icon
              icon="FileTextIcon"
              class="feather-48"
            />
          </p>
          <span>Sin constancia</span>
        </b-col>
        <!-- #endregion::Preview icon -->

        <!-- #region::Add voucher button -->
        <b-col
          cols="12"
          class="text-center mt-1"
        >
          <b-button
            variant="principal-btn"
            class="principal-btn w-100"
            @click="$refs.uploadTaxSituationProofModal.showModal({ id: idModel, type: 'order' })"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            <span class="align-middle">Agregar constancia</span>
          </b-button>
        </b-col>
        <!-- #endregion::Add voucher button -->

      </b-row>
    </b-collapse>
    <!-- #endregion::Downloading file options -->

    <!-- #region::Modal for making rejected quote comment -->
    <UploadTaxSituationProofModal
      v-if="$ability.can('save tax situation', 'Order')"
      ref="uploadTaxSituationProofModal"
      @reload-order="$emit('reload-order')"
    />
    <!-- #endregion::Modal for making rejected quote comment -->

  </b-card>
</template>

<script>
import {
  BCard, BCollapse, BRow, BCol, BButton,
} from 'bootstrap-vue'

import UploadTaxSituationProofModal from '@/modules/trade/quotes/components/modals/UploadTaxSituationProofModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCollapse,
    UploadTaxSituationProofModal,
  },
  props: {
    idModel: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
      collapseItemId: '6',
      iconToggle: 'ChevronDownIcon',
    }
  },
  methods: {
    /**
     * Colapsa el card de total de la cotización
     */
    collapse() {
      this.$root.$emit('bv::toggle::collapse', this.collapseItemId)
      this.visible = !this.visible
      this.setToggleIcon()
    },

    /**
     * Cambia el icono de la flecha de colapsar
     */
    setToggleIcon() {
      this.iconToggle = this.visible ? 'ChevronUpIcon' : 'ChevronDownIcon'
    },
  },
}
</script>

<style lang="scss" scoped>
  .feather-48{
    width: 60px;
    height: 60px;
  }
</style>
