<template>
  <div>
    <!-- #region begin::Form -->
    <validation-observer ref="commentModal">
      <b-form @submit="$event.preventDefault()">

        <b-modal
          ref="comment-modal"
          :title="title"
          centered
          @hidden="resetModal"
        >
          <b-card-text>
            <b-row>
              <b-col>
                <h6><p>{{ subTitle }}</p></h6>
              </b-col>

              <!-- #region::Comment textarea -->
              <b-col md="12">
                <b-form-group
                  label="Comentario*"
                  label-for="textarea-comment"
                >

                  <div
                    slot="label"
                    class="d-flex justify-content-start"
                  >
                    <span>Comentario</span>
                    <h5 class="text-danger">
                      *
                    </h5>
                  </div>

                  <validation-provider
                    #default="{ errors }"
                    name="comentario"
                    vid="Comentario"
                    rules="required"
                  >

                    <b-form-textarea
                      id="textarea-comment"
                      v-model="comment"
                      placeholder="Escribe el comentario"
                      rows="3"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- #endregion::Comment textarea -->

            </b-row>
          </b-card-text>

          <!-- #region::Footer -->
          <template #modal-footer>
            <b-button
              variant="delete-btn"
              class="delete-btn"
              @click="$refs['comment-modal'].hide()"
            >
              Cancelar
            </b-button>
            <b-button
              variant="principal-btn"
              class="principal-btn"
              :disabled="savingComment"
              @click="onSaveComment"
            >
              <b-spinner
                v-if="savingComment"
                class="mr-50"
                small
              />
              <span class="align-middle">Aceptar</span>
            </b-button>
          </template>
          <!-- #endregion::Footer -->

        </b-modal>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
// #region Imports
import { required } from '@validations'
import { ValidationObserver, ValidationProvider, localize } from 'vee-validate'
import {
  BCol, BRow, BModal, VBModal, BCardText, BForm, BFormTextarea, BFormGroup, BButton,
  BSpinner,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// #endregion

export default {
  name: 'CommentModal',
  components: {
    BCol,
    BRow,
    BForm,
    BModal,
    BButton,
    BSpinner,
    BCardText,
    BFormGroup,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    action: {
      type: Function,
      required: true,
    },
    title: {
      type: String,
      default: 'Comentario',
    },
    subTitle: {
      type: String,
      default: 'Escribe un comentario para la operación',
    },
    successTitle: {
      type: String,
      default: 'Operación exitosa',
    },
    successMessage: {
      type: String,
      default: 'La operación se ha completado correctamente.',
    },
    errorMessage: {
      type: String,
      default: 'Error de validación',
    },
    emitReload: {
      type: String,
      default: 'reload',
    },
  },
  data() {
    return {
      resource: '',
      comment: '',
      savingComment: false,
      required,
    }
  },
  async created() {
    localize('es')
  },
  methods: {
    async onSaveComment() {
      const success = await this.$refs.commentModal.validate()
      if (success) {
        this.action(this.comment)
      }
    },
    showModal() {
      this.$refs['comment-modal'].show()
    },
    hideModal() {
      this.$refs['comment-modal'].hide()
    },
    resetModal() {
      this.comment = ''
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
