<template>
  <div>
    <b-form-row align-v="center">

      <!-- #region::Return to orders list link -->
      <b-col
        cols="12"
        class="px-0 mb-1"
      >
        <small>
          <router-link
            :to="{ name: 'orders-home' }"
          >
            <feather-icon
              icon="ChevronLeftIcon"
              class="mr-50 text-secondary"
            />
            <span class="align-middle text-secondary">Regresar</span>
          </router-link>
        </small>
      </b-col>
      <!-- #endregion::Return to orders list link -->

      <!-- #region::Order modal history -->
      <b-col>
        <div class="d-flex justify-content-left">
          <h3 class="align-self-center">
            Orden #{{ order.order.ID }}
          </h3>
          <div
            :class="[statusBorderStyle, statusColorStyle]"
            role="alert"
          >
            {{ order.order.current_status }}
          </div>
          <div>
            <OrderModalHistory
              v-if="historyStatus"
              :history="historyStatus"
            />
          </div>
        </div>
      </b-col>
      <!-- #endregion::Order modal history -->

      <!-- #region::Confirm order autcoming button -->
      <b-col
        v-if="canShowConfirmOrderOutcomingButton && canManageOrders"
        cols="12"
        md="auto"
      >
        <b-button
          variant="principal-btn"
          class="principal-btn"
          :disabled="confirmingOutcoming"
          @click="onConfirmOutcoming"
        >
          <b-spinner
            v-if="confirmingOutcoming"
            class="mr-50"
            small
          />
          <span class="align-middle">Autorizar salida</span>
        </b-button>
      </b-col>
      <!-- #endregion::Confirm order autcoming button -->

      <!-- #region::Edit order button -->
      <b-col
        cols="12"
        md="auto"
      >
        <b-button
          v-if="canShowEditOrderButton && canManageOrders"
          variant="principal-btn"
          class="my-1 my-lg-0 principal-btn"
          @click="editionModeState = true"
        >
          <feather-icon
            icon="Edit3Icon"
            class="mr-50"
          />
          <span class="align-middle">Editar</span>
        </b-button>
      </b-col>
      <!-- #endregion::Edit order button -->

      <!-- #region::Receive order button -->
      <b-col
        v-if="canShowReceiveOrderButton"
        cols="12"
        md="auto"
      >
        <b-button
          variant="principal-btn"
          class="principal-btn"
          :disabled="loadingState"
          @click="onConfirmReceived"
        >
          <b-spinner
            v-if="loadingState"
            class="mr-50"
            small
          />
          <feather-icon
            v-else
            icon="CheckIcon"
            class="mr-50"
          />
          <span class="align-middle">Recibida</span>
        </b-button>
      </b-col>
      <!-- #endregion::Receive order button -->

      <!-- #region::Ready for delivery button -->
      <b-col
        v-if="canShowReadyForDeliveryOrderButton"
        cols="12"
        md="auto"
      >
        <b-button
          variant="principal-btn"
          class="principal-btn"
          :disabled="loadingReadyForDelivery"
          @click="onReadyForDelivery"
        >
          <b-spinner
            v-if="loadingReadyForDelivery"
            class="mr-50"
            small
          />
          <feather-icon
            v-else
            icon="CheckIcon"
            class="mr-50"
          />
          <span class="align-middle">Lista para entrega</span>
        </b-button>
      </b-col>
      <!-- #endregion::Ready for delivery button -->

      <!-- #region::Delivered button -->
      <b-col
        v-if="canShowDeliveryDoneButton"
        cols="12"
        md="auto"
      >
        <b-button
          variant="principal-btn"
          class="principal-btn"
          :disabled="makingDeliveryDone || order.order.Authorized === 'No'"
          @click="onDeliveryOrderDone"
        >
          <b-spinner
            v-if="makingDeliveryDone"
            class="mr-50"
            small
          />
          <span class="align-middle">Entregada</span>
        </b-button>
      </b-col>
      <!-- #endregion::Delivered button -->

      <!-- #region::Stopping for delivery button -->
      <b-col
        v-if="canShowStopOrderButton"
        cols="12"
        md="auto"
      >
        <b-button
          variant="delete-btn"
          class="delete-btn"
          :disabled="loadingState"
          @click="onStoppingForDelivery"
        >
          <b-spinner
            v-if="loadingState"
            class="mr-50"
            small
          />
          <feather-icon
            v-else
            icon="SlashIcon"
            class="mr-50"
          />
          <span class="align-middle">Detener orden</span>
        </b-button>
      </b-col>
      <!-- #endregion::Stopping for delivery button -->

      <!-- #region::Ready for delivery button -->
      <b-col
        v-if="canShowActivateOrderButton"
        cols="12"
        md="auto"
      >
        <b-button
          variant="principal-btn"
          class="principal-btn"
          :disabled="loadingActivatingOrder"
          @click="onActivateOrder"
        >
          <b-spinner
            v-if="loadingActivatingOrder"
            class="mr-50"
            small
          />
          <feather-icon
            v-else
            icon="CheckIcon"
            class="mr-50"
          />
          <span class="align-middle">Activar orden</span>
        </b-button>
      </b-col>
      <!-- #endregion::Ready for delivery button -->

      <!-- #region::Delivery type selector for opening modal -->
      <b-col
        v-if="canShowDeliveryTypeOrderSelect"
        md="2"
      >
        <v-select
          v-model="selectedDeliverType"
          label="text"
          class="style-chooser"
          placeholder="Tipo de entrega"
          :options="getDeliveryTypes"
        />
      </b-col>
      <!-- #endregion::Delivery type selector for opening modal -->

      <!-- #region::Make invoice button -->
      <b-col
        v-if="canShowConfirmOrderInvoice"
        cols="12"
        md="auto"
      >
        <b-button
          variant="principal-btn"
          class="principal-btn"
          :disabled="loadingState"
          @click="onMakeInvoice"
        >
          <b-spinner
            v-if="loadingState"
            class="mr-50"
            small
          />
          <feather-icon
            v-else
            icon="FileTextIcon"
            class="mr-50"
          />
          <span class="align-middle">Realizar factura</span>
        </b-button>
      </b-col>
      <!-- #endregion::Make invoice button -->

      <!-- #region::Invoiced label -->
      <b-col
        v-if="order.order.invoiced"
        cols="12"
        md="auto"
      >
        <div
          class="banner-alert alert-light-green"
          role="alert"
        >
          Factura realizada
        </div>
      </b-col>
      <!-- #endregion::Invoiced label -->

      <!-- #region::Cancel order button -->
      <b-col
        v-if="canShowCancelOrderButton && canManageOrders"
        cols="12"
        md="auto"
      >
        <b-button
          variant="delete-btn"
          class="delete-btn"
          :disabled="loadingState"
          @click="onCanceling"
        >
          <b-spinner
            v-if="loadingState"
            class="mr-50"
            small
          />
          <span class="align-middle">Cancelar</span>
        </b-button>
      </b-col>
      <!-- #endregion::Cancel order button -->

      <!-- #region::Make order outcoming in proccess button -->
      <b-col
        v-if="canShowMakeInProccessTransferButton"
        cols="12"
        md="auto"
      >
        <b-button
          variant="principal-btn"
          class="principal-btn"
          :disabled="makingInProcessTransfer"
          @click="onMakeInProcessTransfer"
        >
          <b-spinner
            v-if="makingInProcessTransfer"
            class="mr-50"
            small
          />
          <feather-icon
            v-else
            icon="ClockIcon"
            class="mr-50"
          />
          <span class="align-middle">Marcar en proceso</span>
        </b-button>
      </b-col>
      <!-- #endregion::Make order outcoming in proccess button -->

      <!-- #region::Confirm order autcoming button -->
      <b-col
        v-if="canShowConfirmTransferButton"
        cols="12"
        md="auto"
      >
        <b-button
          variant="principal-btn"
          class="principal-btn"
          :disabled="confirmingTransfer"
          @click="onConfirmTransfer"
        >
          <b-spinner
            v-if="confirmingTransfer"
            class="mr-50"
            small
          />
          <feather-icon
            v-else
            icon="CheckIcon"
            class="mr-50"
          />
          <span class="align-middle">Traspasada</span>
        </b-button>
      </b-col>
      <!-- #endregion::Confirm order autcoming button -->

      <!-- #region::Download pdf button -->
      <b-col
        v-if="canShowDownloadPdfButton"
        cols="12"
        md="auto"
        :disabled="downloadingFile"
        @click="onDownloadPDF"
      >
        <b-button
          variant="flat-success"
          class="csv-btn"
        >
          <b-spinner
            v-if="downloadingFile"
            class="mr-50"
            small
          />
          <feather-icon
            v-if="!downloadingFile"
            icon="DownloadIcon"
            class="mr-50"
          />
          <span class="align-middle">Descargar PDF</span>
        </b-button>
      </b-col>
      <!-- #endregion::Download pdf button -->

      <!-- #region::Request transfer button -->
      <b-col
        v-if="canShowRequestTransferButton"
        cols="12"
        md="auto"
      >
        <div>
          <OrderRequestTransferModal
            :orderlocation="order.order.location"
            :order-id="order.order.ID"
            @reload-order="$emit('reload-order')"
          />
        </div>
      </b-col>
      <!-- #endregion::Request transfer button -->

      <!-- #region::Cancel request transfer button -->
      <b-col
        v-if="canShowCancelRequestTransferButton"
        cols="12"
        md="auto"
      >
        <b-button
          variant="delete-btn"
          class="delete-btn"
          :disabled="loadingState"
          @click="onCancelRequestTransfer"
        >
          <b-spinner
            v-if="loadingState"
            class="mr-50"
            small
          />
          <span class="align-middle">Cancelar Traspaso</span>
        </b-button>
      </b-col>
      <!-- #endregion::Cancel request transfer button -->

      <!-- #region::Refund type selector -->
      <b-col
        v-if="canShowRefundTypeOrderSelector"
        cols="12"
        md="auto"
      >
        <b-dropdown text="Devolución">
          <b-dropdown-item
            :disabled="getCanMakeRefund === 'Parcial'"
            @click="onMakeCompleteRefund()"
          >
            Completa
          </b-dropdown-item>
          <b-dropdown-item
            :disabled="getCanMakeRefund === 'Completa'"
            @click="$refs.orderPartialRefundModal.showModal()"
          >
            Parcial
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
      <!-- #endregion::Refund type selector -->

      <!-- #region::Cancel update order button -->
      <b-col
        v-if="canShowCancelUpdateOrderButton"
        cols="12"
        md="auto"
      >
        <b-button
          variant="delete-btn"
          class="delete-btn"
          @click="onCancelingOrderUpdate"
        >
          Cancelar
        </b-button>
      </b-col>
      <!-- #endregion::Cancel update order button -->

      <!-- #region::Save changes button -->
      <b-col
        v-if="canShowSaveChangesOrderButton"
        cols="12"
        md="auto"
      >
        <b-button
          variant="principal-btn"
          class="principal-btn"
          @click="callback"
        >
          Guardar cambios
        </b-button>
      </b-col>
      <!-- #endregion::Save changes button -->

      <!-- #region::Deleting order button -->
      <b-col
        v-if="canShowDeleteOrderButton && canManageOrders"
        cols="12"
        md="auto"
      >
        <b-button
          variant="delete-btn"
          class="delete-btn"
          @click="onDeleting"
        >
          Eliminar
        </b-button>
      </b-col>
      <!-- #endregion::Deleting order button -->

      <!-- #region::Approve and reject transfer buttons -->
      <b-col
        v-if="canShowRequestTransferButtons && canManageOrders"
        cols="12"
      >

        <!-- #region::Approve transfer button -->
        <b-button
          variant="flat-success"
          @click="onApproveTransfer"
        >
          <feather-icon
            icon="CheckIcon"
            class="mr-50"
          />
          <span class="align-middle">Aprobar traspaso</span>
        </b-button>
        <!-- #endregion::Approve transfer button -->

        <!-- #region::Reject transfer button -->
        <b-button
          variant="flat-danger"
          @click="onRejectTransfer"
        >
          <feather-icon
            icon="XIcon"
            class="mr-50"
          />
          <span class="align-middle">Rechazar traspaso</span>
        </b-button>
        <!-- #endregion::Reject transfer button -->

      </b-col>
      <!-- #endregion::Approve and reject transfer buttons -->

      <b-col
        v-if="order.order.Destiny && status !== 'Traspasada'"
        cols="12"
        class="mt-1"
      >
        <b-alert
          show
          variant="info"
        >
          <div class="alert-body">
            <feather-icon
              class="mr-25"
              icon="AlertCircleIcon"
            />
            <span class="ml-25">Esta orden se encuentra en proceso de traspaso a <b>{{ order.order.Destiny }}</b></span>
          </div>
        </b-alert>
      </b-col>

      <b-col
        v-if="order.order.Destiny && status === 'Traspasada'"
        cols="12"
        class="mt-1"
      >
        <b-card
          no-body
          class="border border-dark shadow-none p-1"
        >
          <b-card-text>
            <div class="d-flex">
              <feather-icon
                class="mr-50 text-primary"
                icon="AlertCircleIcon"
              />
              <span>Esta orden se traspasó a <b>{{ order.order.Destiny }}.</b></span>
              <span
                v-if="$ability.can('read id_order', 'Order')"
                style="padding-left:4px"
              >Consulta el detalle de la nueva orden
                <router-link
                  :target="'_blank'"
                  :to="{ name: 'order-details', params: { id: order.order.TransferID } }"
                >
                  #{{ order.order.TransferID }} aquí.
                </router-link></span>
            </div>
          </b-card-text>
        </b-card>
      </b-col>

    </b-form-row>

    <!-- #region::Modal for making partial delivery -->
    <OrderPartialDeliveryModal
      ref="orderPartialDeliveryModal"
      :order-id="order.order.ID"
      @on-close-modal="selectedDeliverType = ''"
      @reload-order="$emit('reload-order')"
    />
    <!-- #endregion::Modal for making partial delivery -->

    <!-- #region::Modal for making refund -->
    <OrderPartialRefundModal
      ref="orderPartialRefundModal"
      :order-id="order.order.ID"
      :product-data="productData"
      @on-close-modal="selectedRefundType = ''"
      @reload-order="$emit('reload-order')"
    />
    <!-- #endregion::Modal for making refund -->

    <!-- #region::Stopping order comment modal -->
    <StoppingOrderCommentModal
      v-if="$ability.can('stop', 'Order')"
      ref="stoppingOrderCommentModal"
      @reload-order="$emit('reload-order')"
    />
    <!-- #endregion::Stopping order comment modal -->

  </div>
</template>

<script>
// #region Imports
import { mapGetters, mapActions } from 'vuex'
import {
  BCol, BButton, BFormRow, BSpinner, BAlert, BCard, BCardText, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import OrderModalHistory from '@/modules/trade/orders/components/modals/OrderModalHistory.vue'
import OrderPartialRefundModal from '@/modules/trade/orders/components/modals/OrderPartialRefundModal.vue'
import OrderRequestTransferModal from '@/modules/trade/orders/components/modals/OrderRequestTransferModal.vue'
import StoppingOrderCommentModal from '@/modules/trade/orders/components/modals/StoppingOrderCommentModal.vue'
import OrderPartialDeliveryModal from '@/modules/trade/orders/components/modals/OrderPartialDeliveryModal.vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import getError from '@/helpers/ErrorsHandler'
// #endregion

export default {
  components: {
    BCol,
    BCard,
    BAlert,
    vSelect,
    BButton,
    BFormRow,
    BSpinner,
    BCardText,
    BDropdown,
    BDropdownItem,
    OrderModalHistory,
    OrderPartialRefundModal,
    OrderRequestTransferModal,
    StoppingOrderCommentModal,
    OrderPartialDeliveryModal,
  },
  props: {
    callback: {
      type: Function,
      required: true,
    },
    canManageOrders: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loadingState: false,
      historyStatus: null,
      downloadingFile: false,
      selectedRefundType: '',
      selectedDeliverType: '',
      makingDeliveryDone: false,
      confirmingOutcoming: false,
      confirmingTransfer: false,
      loadingActivatingOrder: false,
      loadingReadyForDelivery: false,
      makingInProcessTransfer: false,
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    ...mapGetters({
      getOrder: 'orders/getOrder',
      getCanMakeRefund: 'orders/getCanMakeRefund',
      getDeliveryTypes: 'orders/getDeliveryTypes',
      getDetailProducts: 'orders/getDetailProducts',
      getDetailCircuits: 'orders/getDetailCircuits',
      getEditionModeState: 'orders/getEditionModeState',
      getDetailProductsPieces: 'orders/getDetailProductsPieces',
      /**
       * Lista de circuitos con precio de oferta seleccionado
       */
      getCircuitsWithOfferPriceSelected: 'orders/getCircuitsWithOfferPriceSelected',
    }),
    order: {
      get() { return this.getOrder },
    },
    status: {
      get() { return this.order.order.current_status },
    },
    editionModeState: {
      get() { return this.getEditionModeState },
      set(value) { this.setEditionModeState(value) },
    },
    detailProducts: {
      get() { return this.getDetailProducts },
    },
    detailCircuits: {
      get() { return this.getDetailCircuits },
    },
    detailProductsPieces: {
      get() { return this.getDetailProductsPieces },
    },
    /**
     * Lista de circuitos con precio de oferta seleccionado
     */
    circuitsWithOfferPriceSelected: {
      get() { return this.getCircuitsWithOfferPriceSelected },
    },
    statusBorderStyle() {
      switch (this.order.order.current_status) {
        case 'Completada':
          return 'banner-alert-bordered'
        case 'Traspasada':
          return 'banner-alert-bordered'
        default:
          return 'banner-alert'
      }
    },
    statusColorStyle() {
      switch (this.order.order.current_status) {
        case 'Aprobada':
          return 'alert-green'
        case 'Lista para entrega':
          return 'alert-yellow'
        case 'Traspaso en proceso':
          return 'alert-yellow'
        case 'Entrega completa':
          return 'alert-blue'
        case 'Entrega parcial':
          return 'alert-cyan'
        case 'En bodega':
          return 'alert-purple'
        case 'Detenida':
          return 'alert-gray'
        case 'Cancelada':
          return 'alert-gray'
        case 'Completada':
          return 'alert-green-white'
        case 'En suministro':
          return 'alert-orange'
        case 'Traspaso':
          return 'alert-marine'
        case 'Traspaso aprobado':
          return 'alert-green'
        case 'Traspasada':
          return 'alert-green-white'
        default:
          return 'alert-blue'
      }
    },
    // #region Showing permission buttons
    canShowReceiveOrderButton() {
      const statuses = ['Traspasada', 'Aprobada']
      return this.$ability.can('confirm received', 'Order')
          && statuses.includes(this.order.order.current_status)
          && !this.editionModeState
    },
    canShowReadyForDeliveryOrderButton() {
      return this.$ability.can('confirm ready_delivery', 'Order')
          && this.order.order.current_status === 'En bodega'
    },
    canShowStopOrderButton() {
      return this.$ability.can('stop', 'Order')
          && (this.order.order.current_status === 'En bodega'
          || this.order.order.current_status === 'Lista para entrega'
          || this.order.order.current_status === 'Entrega parcial'
          || this.order.order.current_status === 'Entrega completa'
          || this.order.order.current_status === 'En suministro')
          && !this.editionModeState
    },
    canShowActivateOrderButton() {
      return this.$ability.can('stop', 'Order')
          && this.order.order.current_status === 'Detenida'
    },
    canShowDeliveryTypeOrderSelect() {
      return this.$ability.can('confirm complete_delivery', 'Order')
          && this.$ability.can('confirm partial_delivery', 'Order')
          && (this.order.order.current_status === 'Lista para entrega'
          || this.order.order.current_status === 'En suministro')
          && !this.editionModeState
    },
    canShowRefundTypeOrderSelector() {
      return this.$ability.can('request', 'Devolution')
          && this.status === 'Completada'
          && this.getCanMakeRefund !== 'No'
    },
    canShowDeleteOrderButton() {
      const status = this.order.order.current_status
      return this.$ability.can('delete', 'Order')
        && (status === 'Cancelada' || status === 'Completada')
    },
    canShowRequestTransferButton() {
      const statuses = ['Aprobada', 'En bodega', 'Lista para entrega']
      const status = this.order.order.current_status
      return this.$ability.can('read request_transfer', 'Order')
        && statuses.includes(status)
    },
    canShowCancelRequestTransferButton() {
      const statusesForAdmin = ['Traspaso en proceso']
      const statusesForusers = ['Traspaso', 'Traspaso aprobado']

      const status = this.order.order.current_status

      if (statusesForAdmin.includes(status)) {
        return this.$ability.can('cancel_order_with_transfer_in_proccess', 'Order')
      } else if (statusesForusers.includes(status)) {
        return this.$ability.can('cancel_transfer', 'Order')
      }
    },
    canShowRequestTransferButtons() {
      const statuses = ['Traspaso']
      const status = this.order.order.current_status
      return this.$ability.can('read aprove_transfer', 'Order')
        && statuses.includes(status)
    },
    canShowMakeInProccessTransferButton() {
      return this.$ability.can('read check_in_transfer', 'Order')
        && this.order.order.current_status === 'Traspaso aprobado'
    },
    canShowConfirmTransferButton() {
      return this.$ability.can('read confirm_transfer', 'Order')
        && this.order.order.current_status === 'Traspaso en proceso'
    },
    canShowConfirmOrderInvoice() {
      const { invoiced } = this.order.order
      return this.$ability.can('confirm billing', 'Order') && !invoiced
    },
    canShowDownloadPdfButton() {
      return this.editionModeState === false
        && this.$ability.can('download', 'Orders')
    },
    canShowCancelOrderButton() {
      const statuses = ['Completada', 'Cancelada', 'Traspasada',
        'Entrega parcial', 'Entrega completa', 'En suministro'
      ]
      return this.editionModeState === false
        && this.$ability.can('cancel', 'Order')
        && !statuses.includes(this.status)
    },
    canShowEditOrderButton() {
      const statuses = ['Traspasada']

      // Si el usuario es coordinador de almacen, se agregan los estatus completada y cancelada
      if (this.userData.Role === 'Coordinador de Almacen') {
        statuses.push('Completada')
        statuses.push('Cancelada')
      }

      return this.editionModeState === false
        && this.$ability.can('delete', 'Order_products')
        && this.$ability.can('delete', 'Order_piece')
        && this.$ability.can('edit unit_price', 'Order')
        && !statuses.includes(this.status)
    },
    canShowConfirmOrderOutcomingButton() {
      return this.$ability.can('approve out', 'Order')
          && this.order.order.Authorized !== 'Si'
          && (this.order.order.current_status === 'Entrega parcial'
          || this.order.order.current_status === 'Entrega completa')
    },
    canShowDeliveryDoneButton() {
      return this.$ability.can('confirm release', 'Order')
          && this.userData.Role === 'Coordinador de Almacen'
          && (this.order.order.current_status === 'Entrega parcial'
          || this.order.order.current_status === 'Entrega completa')
    },
    canShowCancelUpdateOrderButton() {
      return this.editionModeState === true
    },
    canShowSaveChangesOrderButton() {
      return this.editionModeState === true
    },
    canShowRefundButton() {
      return this.getCanMakeRefund !== 'No'
    },
    // #endregion
  },
  watch: {
    selectedDeliverType() {
      if (this.selectedDeliverType) {
        if (this.selectedDeliverType.text === 'Entrega parcial') {
          this.$refs.orderPartialDeliveryModal.showModal()
        } else if (this.selectedDeliverType.text === 'Entrega completa') {
          this.onMakeCompleteDelivery()
        }
      }
    },
    order() {
      this.updateStatusOrder()
    },
  },
  async created() {
    await this.updateStatusOrder()
  },
  methods: {
    ...mapActions({
      stopOrder: 'orders/stopOrder',
      clearData: 'orders/clearData',
      makeRefund: 'orders/makeRefund',
      deleteOrder: 'orders/deleteOrder',
      cancelOrder: 'orders/cancelOrder',
      makeInvoice: 'orders/makeInvoice',
      activateOrder: 'orders/activateOrder',
      confirmTransfer: 'orders/confirmTransfer',
      downloadPDFFile: 'orders/downloadPDFFile',
      loadStatusOrder: 'orders/loadStatusOrder',
      authorizeOutput: 'orders/authorizeOutput',
      makePiecesOutputs: 'orders/makePiecesOutputs',
      checkRefundStatus: 'orders/checkRefundStatus',
      deliveryOrderDone: 'orders/deliveryOrderDone',
      setEditionModeState: 'orders/setEditionModeState',
      confirmReceiveOrder: 'orders/confirmReceiveOrder',
      makeInProcessTransfer: 'orders/makeInProcessTransfer',
      readyForDeliveryOrder: 'orders/readyForDeliveryOrder',
      cancelRequestTransfer: 'orders/cancelRequestTransfer',
      rejectRequestTransfer: 'orders/rejectRequestTransfer',
      approveRequestTransfer: 'orders/approveRequestTransfer',
      cancelRequestTransferInProgress: 'orders/cancelRequestTransferInProgress',
    }),
    async updateStatusOrder() {
      const response = await this.loadStatusOrder(this.order.order.ID)
      const statuses = response.data.data

      if (statuses.length === 0) {
        this.showToast(
          'Historial de estatus',
          'No se ha cargado información del historial de estatus.',
          'warning',
        )

        return
      }

      this.historyStatus = statuses
    },
    async onDownloadPDF() {
      this.downloadingFile = true
      await this.downloadPDFFile(this.order.order.ID)
      this.downloadingFile = false
    },
    async onConfirmReceived() {
      try {
        if (!this.order.order.ConfirmedDeliveryDate) {
          this.showToast(
            'Error de validación',
            'Para marcar la orden de recibida es necesario aprobar la fecha de entrega requerida.',
            'warning',
          )

          return
        }

        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()

        this.loadingState = true
        await this.confirmReceiveOrder(this.order.order.ID)
        this.$swal.close()

        this.showToast(
          'Orden confirmada',
          'La orden ha sido confirmada correctamente.',
          'success',
        )

        this.$emit('reload-order')
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
        this.$swal.close()
      } finally {
        this.loadingState = false
      }
    },
    async onReadyForDelivery() {
      try {
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()

        this.loadingReadyForDelivery = true
        await this.readyForDeliveryOrder(this.order.order.ID)
        this.$swal.close()

        this.showToast(
          'Orden lista para entrega',
          'La orden ha tomado el estado de lista para entrega correctamente.',
          'success',
        )

        this.$emit('reload-order')
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
        this.$swal.close()
      } finally {
        this.loadingReadyForDelivery = false
      }
    },
    onStoppingForDelivery() {
      this.$refs.stoppingOrderCommentModal.showModal(this.order.order.ID)
    },
    async onActivateOrder() {
      try {
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()

        this.loadingActivatingOrder = true
        await this.activateOrder(this.order.order.ID)
        this.$swal.close()

        this.showToast(
          'Orden activada',
          'La orden ha sido activada correctamente.',
          'success',
        )

        this.$emit('reload-order')
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
        this.$swal.close()
      } finally {
        this.loadingActivatingOrder = false
      }
    },
    // * 11/01/2023 - TAG: Admin can confirm outcoming when coordinator make partial delivery or complete delivery
    async onConfirmOutcoming() {
      try {
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()

        this.confirmingOutComing = true
        await this.deliveryOrderDone(this.order.order.ID)
        this.$swal.close()

        this.showToast(
          'Salida autorizada',
          'La salida de la orden ha sido autorizada correctamente.',
          'success',
        )

        this.$emit('reload-order')
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
        this.$swal.close()
      } finally {
        this.confirmingOutComing = false
      }
    },
    async onCanceling() {
      const result = await this.$swal({
        title: '¿Deseas continuar?',
        text: `¡La cotización #${this.order.order.ID} será cancelada! Esta acción no se podrá deshacer!`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
      if (result.isConfirmed) {
        try {
          this.loadingState = true
          await this.cancelOrder(this.order.order.ID)

          this.showToast(
            'Orden cancelada',
            'La orden ha sido cancelada correctamente.',
            'success',
          )

          this.$emit('reload-order')
        } catch (error) {
          this.showToast('Error de validación', getError(error), 'danger')
        } finally {
          this.loadingState = false
        }
      }
    },
    async onCancelRequestTransfer() {
      const result = await this.$swal({
        title: '¿Deseas continuar?',
        text: '¡La solicitud de traspaso será cancelada!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
      if (result.isConfirmed) {
        const formData = new FormData()
        formData.append('id', this.order.order.ID)
        try {
          this.loadingState = true

          const statusesForAdmin = ['Traspaso en proceso']
          const statusesForusers = ['Traspaso', 'Traspaso aprobado']

          const status = this.order.order.current_status

          if (statusesForAdmin.includes(status)) {
            await this.cancelRequestTransferInProgress(formData)
          } else if (statusesForusers.includes(status)) {
            await this.cancelRequestTransfer(formData)
          }


          this.showToast(
            'Solicitud de traspaso cancelada',
            'La solicitud de traspaso ha sido cancelada correctamente.',
            'success',
          )

          this.$emit('reload-order')
        } catch (error) {
          this.showToast('Error de validación', getError(error), 'danger')
        } finally {
          this.loadingState = false
        }
      }
    },
    async onApproveTransfer() {
      const result = await this.$swal({
        title: '¿Deseas continuar?',
        text: '¡La solicitud de traspaso será aprobada!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
      if (result.isConfirmed) {
        const formData = new FormData()
        formData.append('id', this.order.order.ID)
        try {
          this.$swal({
            title: 'Espere por favor',
            allowOutsideClick: false,
          })

          this.$swal.showLoading()
          await this.approveRequestTransfer(formData)

          this.showToast(
            'Solicitud de traspaso aprobada',
            'La solicitud de traspaso ha sido aprobada correctamente.',
            'success',
          )

          this.$emit('reload-order')
        } catch (error) {
          this.showToast('Error de validación', getError(error), 'danger')
        } finally {
          this.loadingState = false
        }
      }
    },
    async onMakeInProcessTransfer() {
      try {
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()

        this.makingInProcessTransfer = true
        await this.makeInProcessTransfer(this.order.order.ID)
        this.$swal.close()

        this.showToast(
          'Orden confirmada',
          'La orden se ha marcado en proceso correctamente.',
          'success',
        )

        this.$emit('reload-order')
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
        this.$swal.close()
      } finally {
        this.makingInProcessTransfer = false
      }
    },
    async onConfirmTransfer() {
      try {
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()

        this.confirmingTransfer = true
        await this.confirmTransfer(this.order.order.ID)
        this.$swal.close()

        this.showToast(
          'Orden confirmada',
          'La confirmación de traspaso se ha realizado correctamente.',
          'success',
        )

        this.$router.push({ name: 'orders-home' })
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
        this.$swal.close()
      } finally {
        this.confirmingTransfer = false
      }
    },
    async onRejectTransfer() {
      const result = await this.$swal({
        title: '¿Deseas continuar?',
        text: '¡La solicitud de traspaso será rechazada!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
      if (result.isConfirmed) {
        const formData = new FormData()
        formData.append('id', this.order.order.ID)
        try {
          this.$swal({
            title: 'Espere por favor',
            allowOutsideClick: false,
          })

          this.$swal.showLoading()
          await this.rejectRequestTransfer(formData)

          this.showToast(
            'Solicitud de traspaso rechazada',
            'La solicitud de traspaso ha sido rechazada correctamente.',
            'success',
          )

          this.$emit('reload-order')
        } catch (error) {
          this.showToast('Error de validación', getError(error), 'danger')
        } finally {
          this.loadingState = false
        }
      }
    },
    async onCancelingOrderUpdate() {
      this.clearData()
      this.$emit('reload-order')
    },
    async onMakeInvoice() {
      try {
        this.loadingState = true
        await this.makeInvoice(this.order.order.ID)

        this.showToast(
          'Factura confirmada',
          'La factura ha sido confirmada correctamente.',
          'success',
        )

        this.$emit('reload-order')
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.loadingState = false
      }
    },
    async onDeleting() {
      const result = await this.$swal({
        title: '¿Deseas eliminar la orden?',
        text: '¡Esta acción no se podrá deshacer!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Eliminar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })

      if (result.isConfirmed) {
        try {
          this.$swal({
            title: 'Espere por favor',
            allowOutsideClick: false,
          })

          this.$swal.showLoading()

          await this.deleteOrder(this.order.order.ID)

          this.$swal.fire('Eliminada', '¡La orden ha sido eliminado correctamente!', 'success')
          this.$router.push({ name: 'orders-home' })
        } catch (error) {
          this.showToast('Error de validación', getError(error), 'danger')
        }
      }
    },
    // * 11/01/2023 - TAG: Coordinator only can make complete delivery. It needs to be confirmed by admin
    async onMakeCompleteDelivery() {
      const result = await this.$swal({
        title: '¿Deseas continuar?',
        text: '¡La orden se establecerá a tipo de entrega completa!',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })

      if (result.isConfirmed) {
        try {
          this.$swal({
            title: 'Espere por favor',
            allowOutsideClick: false,
          })

          this.$swal.showLoading()

          const formData = new FormData()

          formData.append('delivery_type', 6)

          this.detailProductsPieces.forEach(piece => {
            if (piece.Delivered !== 'Entregado' && piece.Delivered !== 'Si') {
              formData.append('pieces[]', piece.IdQuotesCircuitsProductsPiece)
            }
          })

          this.detailCircuits.forEach(circuit => {
            circuit.products.forEach(product => {
              product.pieces.forEach(piece => {
                if (piece.Delivered !== 'Entregado' && piece.Delivered !== 'Si') {
                  formData.append('pieces[]', piece.IdQuotesCircuitsProductsPiece)
                }
              })
            })
          })

          // eslint-disable-next-line no-restricted-syntax
          for (const pair of formData.entries()) {
            console.log(`${pair[0]}, ${pair[1]}`)
          }

          await this.makePiecesOutputs({ id: this.order.order.ID, formData })

          this.showToast(
            'Tipo de entrega establecida',
            'El tipo de entrega de la orden ha sido establecido correctamente a entrega completa.',
            'success',
          )

          this.$emit('reload-order')
        } catch (error) {
          this.showToast('Error de validación', getError(error), 'danger')
        }
      } else {
        this.selectedDeliverType = ''
      }
    },
    // * 11/01/2023 - TAG: Coordinator only can make done deliveries. They take "En Suministro" after this
    async onDeliveryOrderDone() {
      try {
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()

        this.makingDeliveryDone = true
        await this.deliveryOrderDone(this.order.order.ID)
        this.$swal.close()

        this.showToast(
          'Orden entregada',
          'Los productos de la orden han sido entregados correctamente.',
          'success',
        )

        this.$emit('reload-order')
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
        this.$swal.close()
      } finally {
        this.makingDeliveryDone = false
      }
    },
    // * 11/01/2023 - TAG: Seller and wholesaler only can make complete refund. It needs to be confirmed by admin
    async onMakeCompleteRefund() {
      const result = await this.$swal({
        title: '¿Deseas continuar?',
        text: '¡Se generará una devolución completa!',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })

      if (result.isConfirmed) {
        try {
          this.$swal({
            title: 'Espere por favor',
            allowOutsideClick: false,
          })

          this.$swal.showLoading()

          const formData = new FormData()

          formData.append('ID', this.order.order.ID)

          this.detailProducts.forEach(orderProduct => {
            formData.append('circuits_products[]', this.productData(orderProduct))
          })

          this.detailCircuits.forEach(orderCircuit => {
            orderCircuit.products.forEach(product => {
              formData.append('circuits_products[]', this.productData(product, orderCircuit.IdCircuit))
            })
          })

          // eslint-disable-next-line no-restricted-syntax
          for (const pair of formData.entries()) {
            console.log(`${pair[0]}, ${pair[1]}`)
          }

          await this.makeRefund(formData)
          await this.checkRefundStatus(this.order.order.ID)

          this.showToast(
            'Devolución creada',
            'La devolución se ha creado correctamente.',
            'success',
          )

          this.$emit('reload-order')
        } catch (error) {
          this.$swal.close()
          this.showToast('Error de validación', getError(error), 'danger')
        }
      } else {
        this.selectedRefundType = ''
      }
    },
    productData(product, idCircuit = '') {
      const pieces = []

      product.pieces.forEach(piece => {
        pieces.push({ idPiece: piece.IdPiece })
      })

      const offertedCircuit = this.circuitsWithOfferPriceSelected.find(c => c.IdCircuit === idCircuit)

      let selectedUnitPrice = 0
      let selectedUnitPriceOrigin = ''
      let selectedUnitPriceType = ''

      if (idCircuit && !offertedCircuit) {
        selectedUnitPrice = product.unitPrice
        selectedUnitPriceOrigin = product.unitPriceOrigin
        selectedUnitPriceType = product.unitPriceType
      } else if (!idCircuit && !offertedCircuit) {
        selectedUnitPrice = product.unitPrice || product.UnitPrice
        selectedUnitPriceOrigin = product.unitPriceOrigin || product.UnitPriceOrigin
        selectedUnitPriceType = product.unitPriceType || product.UnitPriceType
      }

      const productData = JSON.stringify({
        idCircuit,
        idProduct: product.IdProduct,
        unitPrice: selectedUnitPrice,
        unitPriceOrigin: selectedUnitPriceOrigin,
        unitPriceType: selectedUnitPriceType,
        offerPrice: offertedCircuit?.OfferPrice || '',
        qty: pieces.length,
        pieces,
      })

      return productData
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import '@core/scss/vue/libs/vue-autosuggest.scss';
  .style-chooser {
    background: #ffffff;
  }
  .alert-info {
    border: 1px solid #00cfe8;
  }
</style>
