<template>
  <b-modal
    id="partial-refund-modal"
    ref="partial-refund-modal"
    title="Devolución parcial"
    centered
    size="xl"
    @hidden="onCloseModal"
  >
    <b-card-text>

      <!-- #region::Products list -->
      <b-row>

        <b-col md="12">
          Selecciona los productos que llegaron
        </b-col>

        <!-- #region begin::Searcher -->
        <b-col
          class="my-2"
          md="4"
        >
          <BasicSearcher
            :callback="handleSearch"
            placeholder="Buscar productos y circuitos"
          />
        </b-col>
        <!-- #endregion end::Searcher -->

        <!-- #region::Products list -->
        <b-col md="12">
          <OrderProductsDetails
            :selectable="true"
            :show-refund-tag="true"
          />
        </b-col>
        <!-- #endregion::Products list -->

        <b-col md="12">
          <OrderCircuitsDetails
            :selectable="true"
            :show-refund-tag="true"
            class="mt-2"
          />
        </b-col>

      </b-row>
      <!-- #endregion::Products list -->

    </b-card-text>

    <!-- #region::Footer -->
    <template #modal-footer>
      <b-button
        variant="delete-btn"
        class="delete-btn"
        @click="hideModal"
      >
        Cancelar
      </b-button>
      <b-button
        variant="principal-btn"
        class="principal-btn"
        :disabled="makingRefund"
        @click="onAddToPartialDeliveryList"
      >
        <b-spinner
          v-if="makingRefund"
          class="mr-50"
          small
        />
        <span class="align-middle">Confirmar</span>
      </b-button>
    </template>
    <!-- #endregion::Footer -->

  </b-modal>
</template>

<script>
// #region Imports
import { mapGetters, mapActions } from 'vuex'
import {
  BRow, BCol, VBModal, BCardText, BButton, BSpinner,
} from 'bootstrap-vue'

import BasicSearcher from '@/components/forms/BasicSearcher.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import getError from '@/helpers/ErrorsHandler'
// #endregion

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BSpinner,
    BCardText,
    BasicSearcher,
    OrderCircuitsDetails: () => import('@/modules/trade/orders/components/OrderCircuitsDetails.vue'),
    OrderProductsDetails: () => import('@/modules/trade/orders/components/OrderProductsDetails.vue'),
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    orderId: {
      type: String,
      required: true,
    },
    productData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      makingRefund: false,
    }
  },
  computed: {
    ...mapGetters({
      getOrder: 'orders/getOrder',
      getDetailProducts: 'orders/getDetailProducts',
      getDetailCircuits: 'orders/getDetailCircuits',
      getDetailProductsPieces: 'orders/getDetailProductsPieces',
      getPartialDeliveryProvitionalProducts: 'orders/getPartialDeliveryProvitionalProducts',
      getPartialDeliveryProvitionalCircuits: 'orders/getPartialDeliveryProvitionalCircuits',
      getPartialDeliveryProvitionalProductsPieces: 'orders/getPartialDeliveryProvitionalProductsPieces',

      // * 27/01/2023 - TAG: For validating selection products on modal delivery type
      getDetailCircuitsWithoutDeliveredState: 'orders/getDetailCircuitsWithoutDeliveredState',
      getDetailProductsPiecesWithoutDeliveredState: 'orders/getDetailProductsPiecesWithoutDeliveredState',
    }),
    order: {
      get() { return this.getOrder },
    },
    detailProducts: {
      get() { return this.getDetailProducts },
    },
    detailCircuits: {
      get() { return this.getDetailCircuits },
    },
    detailProductsPieces: {
      get() { return this.getDetailProductsPieces },
    },
    partialDeliveryProvitionalProducts: {
      get() { return this.getPartialDeliveryProvitionalProducts },
    },
    partialDeliveryProvitionalCircuits: {
      get() { return this.getPartialDeliveryProvitionalCircuits },
    },
    partialDeliveryProvitionalProductsPieces: {
      get() { return this.getPartialDeliveryProvitionalProductsPieces },
    },
    detailCircuitsWithoutDeliveredState: {
      get() { return this.getDetailCircuitsWithoutDeliveredState },
    },
    detailProductsPiecesWithoutDeliveredState: {
      get() { return this.getDetailProductsPiecesWithoutDeliveredState },
    },
  },
  methods: {
    ...mapActions({
      makeRefund: 'orders/makeRefund',
      setSearchTerm: 'orders/setSearchTerm',
      checkRefundStatus: 'orders/checkRefundStatus',
      makePiecesOutputs: 'orders/makePiecesOutputs',
      clearDeliveryState: 'orders/clearDeliveryState',
    }),
    showModal() {
      this.$refs['partial-refund-modal'].show()
    },
    hideModal() {
      this.$refs['partial-refund-modal'].hide()
    },
    async onAddToPartialDeliveryList() {
      const detailpiecesCount = this.detailProductsPieces.length
      const detailProductsPiecesWithoutDeliveredStateCount = this.detailProductsPiecesWithoutDeliveredState.length

      const detailCircuitsCount = this.detailCircuits.length
      const detailCircuitsWithoutDeliveredStateCount = this.detailCircuitsWithoutDeliveredState.length

      const deliveryPiecesCount = this.partialDeliveryProvitionalProductsPieces.length
      const deliveryCircuitsCount = this.partialDeliveryProvitionalCircuits.length

      let samePiecesCount = false
      let sameCircuitsCount = false

      if (this.order.order.current_status !== 'En suministro') {
        if (deliveryPiecesCount > 0 && (detailpiecesCount === deliveryPiecesCount)) {
          samePiecesCount = true
        }

        if (deliveryCircuitsCount > 0 && detailCircuitsCount === deliveryCircuitsCount) {
          sameCircuitsCount = true
        }

        if (samePiecesCount && deliveryCircuitsCount === 0 && detailCircuitsWithoutDeliveredStateCount === 0) {
          sameCircuitsCount = true
        }

        if (sameCircuitsCount && deliveryPiecesCount === 0 && detailProductsPiecesWithoutDeliveredStateCount === 0) {
          samePiecesCount = true
        }
      } else {
        if (deliveryPiecesCount > 0 && detailProductsPiecesWithoutDeliveredStateCount === deliveryPiecesCount) {
          samePiecesCount = true
        }

        if (deliveryCircuitsCount > 0 && detailCircuitsWithoutDeliveredStateCount === deliveryCircuitsCount) {
          sameCircuitsCount = true
        }

        if (samePiecesCount && deliveryCircuitsCount === 0 && detailCircuitsWithoutDeliveredStateCount === 0) {
          sameCircuitsCount = true
        }

        if (sameCircuitsCount && deliveryPiecesCount === 0 && detailProductsPiecesWithoutDeliveredStateCount === 0) {
          samePiecesCount = true
        }
      }

      if (samePiecesCount && sameCircuitsCount) {
        this.showToast(
          'Error de validación',
          'No se pueden seleccionar todos los productos para generar la devolución.',
          'warning',
        )

        return
      }

      const isInRefund = piece => piece.Devolution === 'Si'
      const inRefund = this.partialDeliveryProvitionalProductsPieces.some(isInRefund)

      if (inRefund) {
        this.showToast(
          'Error de validación',
          'Una o más piezas se encuentran en una devolución.',
          'warning',
        )

        return
      }

      const result = await this.$swal({
        title: '¿Deseas continuar?',
        text: '¡Los productos y circuitos seleccionados serán devueltos!',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Solicitar devolución',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })

      if (result.isConfirmed) {
        try {
          this.$swal({
            title: 'Cargando...',
            allowOutsideClick: false,
          })
          this.$swal.showLoading()

          this.makingRefund = true
          const formData = new FormData()

          formData.append('ID', this.order.order.ID)

          this.partialDeliveryProvitionalProducts.forEach(orderProduct => {
            formData.append('circuits_products[]', this.productData(orderProduct))
          })

          this.partialDeliveryProvitionalCircuits.forEach(orderCircuit => {
            orderCircuit.products.forEach(product => {
              formData.append('circuits_products[]', this.productData(product, orderCircuit.IdCircuit))
            })
          })

          // eslint-disable-next-line no-restricted-syntax
          for (const pair of formData.entries()) {
            console.log(`${pair[0]}, ${pair[1]}`)
          }

          await this.makeRefund(formData)
          await this.checkRefundStatus(this.order.order.ID)

          this.$swal.close()

          this.showToast(
            'Devolución creada',
            'La devolución se ha creado correctamente.',
            'success',
          )

          this.$emit('reload-order')
          this.hideModal()
        } catch (error) {
          this.showToast('Error de validación', getError(error), 'danger')
          this.$swal.close()
        } finally {
          this.makingRefund = false
        }
      }
    },
    handleSearch(searchTerm) {
      this.setSearchTerm(searchTerm)
    },
    onCloseModal() {
      this.detailCircuits.forEach(detailCircuit => {
        const circuit = detailCircuit
        circuit.selected = false
      })

      this.clearDeliveryState()
      this.$emit('on-close-modal')
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
