<template>
  <div>
    <!-- #region begin::Form -->
    <validation-observer ref="orderPaymentForm">
      <b-form @submit="$event.preventDefault()">

        <b-modal
          id="order-payment-modal"
          ref="order-payment-modal"
          :title="id ? 'Actualizar pago' : 'Registrar pago'"
          centered
          @hidden="onCancelPayment"
        >
          <b-card-text>
            <b-row>

              <!-- #region::Available count -->
              <b-col md="6">
                Cantidad disponible
              </b-col>
              <b-col
                md="6"
                class="text-right"
              >
                {{ availableCount | currency }}
              </b-col>
              <b-col md="12">
                <hr>
              </b-col>
              <!-- #endregion::Available count -->

              <!-- #region::Pending payment -->
              <b-col md="6">
                Pendiente de pago
              </b-col>
              <b-col
                md="6"
                class="text-right"
              >
                {{ pendingPayment | currency }}
              </b-col>
              <b-col md="12">
                <hr>
              </b-col>
              <!-- #endregion::Pending payment -->

              <!-- #region::Folio input -->
              <b-col md="12">
                <TextInputWithValidation
                  v-model="folio"
                  vid="folio"
                  rules="required"
                  type="text"
                  label="Folio"
                  :label-class="['red-dot']"
                  name="folio"
                  maxlength="250"
                  placeholder="Escribe el folio"
                />
              </b-col>
              <!-- #endregion::Folio input -->

              <!-- #region::Count input -->
              <b-col md="6">
                <TextInputWithValidation
                  v-model="count"
                  vid="count"
                  :rules="{
                    required: true,
                    negative,
                    notalpha,
                    decimal,
                    'max_value': 1000000000,
                    'min_value': isSelectedPaymentMethodForCredit ? 0 : 0.01,
                  }"
                  type="text"
                  :label-class="['red-dot']"
                  label="Cantidad"
                  name="cantidad"
                  maxlength="12"
                  placeholder="100.00"
                  prepend="$"
                />
              </b-col>
              <!-- #endregion::Count input -->

              <!-- #region::Exchange type input -->
              <b-col md="6">
                <TextInputWithValidation
                  v-model="exchangeType"
                  vid="exchangeType"
                  :rules="{
                    required: usdCount,
                    negative,
                    notalpha,
                    decimal,
                  }"
                  type="text"
                  :label-class="[usdCount ? 'red-dot' : '']"
                  label="Tipo de cambio"
                  name="tipo de cambio"
                  maxlength="12"
                  placeholder="100.00"
                  prepend="$"
                  :disabled="!usdCount"
                />
              </b-col>
              <!-- #endregion::Exchange type input -->

              <!-- #region::Update data checkbox -->
              <b-col md="12">
                <b-form-checkbox
                  v-model="usdCount"
                  class="mb-1"
                >
                  USD
                </b-form-checkbox>
              </b-col>
              <!-- #endregion::Update data checkbox -->

              <!-- #region::Payment method select -->
              <b-col md="12">
                <SelectWithValidation
                  v-model="selectedPaymentMethod"
                  vid="selectedPaymentMethod"
                  rules="required"
                  :label-class="['red-dot']"
                  name="forma de pago"
                  label="Forma de pago"
                  property="method"
                  placeholder="Selecciona la forma de pago"
                  :options="getPaymentMethods"
                />
              </b-col>
              <!-- #endregion::Payment method select -->

              <!-- #region::Order or Refund select -->
              <b-col
                v-if="isSelectedPaymentMethodForBalances"
                md="12"
              >
                <SelectWithValidation
                  v-model="selectedOrderRefund"
                  vid="selectedOrderRefund"
                  rules="required"
                  :label-class="['red-dot']"
                  name="orden/devolución"
                  label="Orden/Devolución"
                  property="text"
                  placeholder="Selecciona la orden/devolución"
                  :options="formatedOrdersRefunds"
                />
              </b-col>
              <!-- #endregion::Order or Refund select -->

              <!-- #region::Payment date picker -->
              <b-col
                md="12"
                class="mt-1"
              >
                <DatePickerWithValidation
                  v-model="paymentDate"
                  vid="paymentDate"
                  rules="required"
                  label="Fecha del comprobante del pago"
                  :show-required-dot="true"
                  name="fecha del comprobante del pago"
                  placeholder="Selecciona la fecha del comprobante del pago"
                />
              </b-col>
              <!-- #endregion::Payment date picker -->

              <!-- #region::Invoice required radiobuttons -->
              <b-col
                md="6"
                class="mt-1"
              >
                <b-form-group>
                  <label>Solicitud de factura</label>
                  <div class="demo-inline-spacing">
                    <b-form-radio
                      v-model="invoiceRequired"
                      value="No"
                      name="type-radios"
                      class="mt-0"
                      checked
                    >
                      No
                    </b-form-radio>
                    <b-form-radio
                      v-model="invoiceRequired"
                      value="Si"
                      name="type-radios"
                      class="mt-0"
                    >
                      Si
                    </b-form-radio>
                  </div>
                </b-form-group>
              </b-col>
              <!-- #endregion::Invoice required radiobuttons -->

              <!-- #region::Images inputs group -->
              <b-col
                md="4"
                class="mt-1"
              >
                <div
                  slot="label"
                  class="d-flex justify-content-start"
                >
                  <small>Imagen del pago</small>
                  <h5 class="text-danger">
                    *
                  </h5>
                </div>
                <b-form-group>
                  <ImagesLoader
                    ref="img01"
                    class="w-75"
                    :image="images[0]"
                    @image-loaded="imageLoaded"
                  />
                </b-form-group>
              </b-col>

              <b-col
                md="6"
                offset-md="6"
              >
                <b-alert
                  variant="danger"
                  :show="!imagesLoaded && submitted"
                  class="mb-2"
                >
                  <div class="alert-body">
                    <small>Es necesario agregar imagen del pago</small>
                  </div>
                </b-alert>
              </b-col>

              <b-col
                md="6"
                offset-md="6"
              >
                <small class="text-primary">Arrastra o selecciona la imagen</small>
              </b-col>
              <!-- #endregion::Images inputs group -->

            </b-row>
          </b-card-text>

          <!-- #region::Footer -->
          <template #modal-footer>
            <b-button
              variant="delete-btn"
              class="delete-btn"
              @click="onCancelPayment"
            >
              Cancelar
            </b-button>
            <b-button
              variant="principal-btn"
              class="principal-btn"
              @click="onRegisterPayment"
            >
              {{ id ? 'Guardar cambios' : 'Registrar pago' }}
            </b-button>
          </template>
          <!-- #endregion::Footer -->
        </b-modal>

      </b-form>
    </validation-observer>
  </div>
</template>

<script>
// #region Imports
import { mapActions, mapGetters } from 'vuex'
import { ValidationObserver, localize } from 'vee-validate'
import {
  required, decimal, notalpha, negative, maxValue,
} from '@validations'
import {
  BCol, BRow, BModal, VBModal, BButton, BCardText, BForm, BFormCheckbox, BFormRadio,
  BFormGroup, BAlert,
} from 'bootstrap-vue'

import ImagesLoader from '@/components/forms/ImagesLoader.vue'
import SelectWithValidation from '@/components/forms/SelectWithValidation.vue'
import TextInputWithValidation from '@/components/forms/TextInputWithValidation.vue'
import DatePickerWithValidation from '@/components/forms/DatePickerWithValidation.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import getError from '@/helpers/ErrorsHandler'
import convertImageToBase64 from '@/helpers/ConvertImageToBase64'
// #endregion

const PAYMENT_METHOD_CREDIT = 'Crédito'
const PAYMENT_METHOD_FOR_BALANCES = 'Saldo a favor'

export default {
  components: {
    BCol,
    BRow,
    BForm,
    BModal,
    BAlert,
    BButton,
    BCardText,
    BFormRadio,
    BFormGroup,
    ImagesLoader,
    BFormCheckbox,
    ValidationObserver,
    SelectWithValidation,
    TextInputWithValidation,
    DatePickerWithValidation,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      id: null,
      folio: '',
      count: '',
      images: [],
      paymentDate: '',
      usdCount: false,
      exchangeType: '',
      invoiceRequired: 'No',
      selectedOrderRefund: '',
      selectedPaymentMethod: '',

      availableCount: 0,
      pendingPayment: 0,

      // * 05/10/2022 - TAG: Validations
      decimal,
      required,
      notalpha,
      negative,
      maxValue,

      quoteId: null,

      base64: '',

      submitted: false,
      imagesLoaded: false,
      pay_id: null,

      /**
       * Orders and refunds to make payment with credit balance
       */
      ordersRefunds: [],
      /**
       * Indica que la información del pago se carga desde la lista de reportes
       */
      updateFromReports: false,
    }
  },
  computed: {
    ...mapGetters({
      getPaymentMethods: 'quotes/getPaymentMethods',
    }),
    /**
     * Indica si el método de pago seleccionado es saldo a favor
     */
    isSelectedPaymentMethodForBalances() {
      return this.selectedPaymentMethod?.method === PAYMENT_METHOD_FOR_BALANCES
    },
    /**
     * Indica si el método de pago seleccionado es crédito
     */
    isSelectedPaymentMethodForCredit() {
      return this.selectedPaymentMethod?.method === PAYMENT_METHOD_CREDIT
    },
    /**
     * Ordenes y devoluciones formateadas para el select
     */
    formatedOrdersRefunds() {
      return this.ordersRefunds.map(orderRefund => ({
        id: `${orderRefund.id}${orderRefund.type}`,
        text: orderRefund.text,
        type: orderRefund.type,
        value: orderRefund.value,
        idResource: orderRefund.id,
      }))
    },
  },
  watch: {
    usdCount() {
      if (!this.usdCount) {
        this.exchangeType = ''
      }
    },
    async selectedPaymentMethod() {
      if (this.isSelectedPaymentMethodForBalances) {
        this.loadCreditBalancesList()
      }
    },
  },
  async created() {
    localize('es')
    await this.loadPaymentMethods()
  },
  methods: {
    ...mapActions({
      createPayment: 'quotes/createPayment',
      updatePayment: 'quotes/updatePayment',
      createImageQuote: 'quotes/createImageQuote',
      downloadImageBlob: 'quotes/downloadImageBlob',
      loadPaymentResume: 'quotes/loadPaymentResume',
      loadPaymentMethods: 'quotes/loadPaymentMethods',
      loadCreditBalances: 'quotes/loadCreditBalances',
    }),
    async onRegisterPayment() {
      const success = await this.$refs.orderPaymentForm.validate()
      this.submitted = true

      if (!this.$refs.img01.file) {
        this.showToast(
          'Error de validación',
          'Es necesario agregar imagen del pago.',
          'danger',
        )
        this.imagesLoaded = false
        return
      }

      if (success) {
        this.$swal({
          title: 'Espere por favor',
          allowOutsideClick: false,
        })

        this.$swal.showLoading()

        const formData = new FormData()
        formData.append('quotation_id', this.id || this.quoteId)
        formData.append('invoice', this.folio)
        formData.append('payment', this.count)
        formData.append('exchange_rate', this.exchangeType || '')
        formData.append('payment_method_id', this.selectedPaymentMethod.id)
        formData.append('invoice_required', this.invoiceRequired)
        formData.append('voucher_date', this.paymentDate)

        if (this.isSelectedPaymentMethodForBalances) {
          formData.append('positive_balance_resource_id', this.selectedOrderRefund.idResource)
          formData.append('positive_balance_resource_type', this.selectedOrderRefund.type)
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const pair of formData.entries()) {
          console.log(`${pair[0]}, ${pair[1]}`)
        }

        try {
          const response = this.id
            ? await this.updatePayment({ id: this.pay_id ?? this.id, formData })
            : await this.createPayment(formData)

          let responseImage = null

          // Si se crea el pago, se crea la imagen
          if (response.data.data.id) {
            const formImage = new FormData()
            formImage.append('idQuote', response.data.data.id)
            formImage.append('files[]', this.$refs.img01.file)

            if (this.updateFromReports) {
              formImage.append('update', true)
            }

            responseImage = await this.createImageQuote(formImage)
          }

          this.showSwalFire('¡El pago se ha registrado correctamente!', responseImage.data.data.report)
          this.hideModal()
        } catch (error) {
          this.$swal.close()
          this.showToast('Error de validación', getError(error), 'danger')
        }
      } else {
        this.showToast(
          'Error de validación',
          'Se encontraron uno o más errores en la validación del formulario del producto.',
          'danger',
        )
      }
    },

    /**
     * Carga de formulario de pago
     *
     * @summary Carga el resumen de la cotizacion y muestra el formulario de pago
     * @param {Number} idQuote - Identificador de la cotización
     */
    async showModal(idQuote) {
      this.quoteId = idQuote

      const response = await this.loadPaymentResume(this.quoteId)

      if (response) {
        this.availableCount = response.data.data.total
        this.pendingPayment = response.data.data.diff
      }

      this.$refs['order-payment-modal'].show()
    },

    /**
    * Edicion de pago
    *
    * @summary Cargo los datos del pago a editar
    * @param {Object} payment - Objeto con los datos del pago
    * @exception - Muestra un mensaje de error si no se puede cargar el pago
    */
    async showModalAndLoadExistingPayment(payment) {
      try {
        this.id = payment.quotation_id
        const response = await this.loadPaymentResume(payment.quotation_id)

        if (response) {
          this.availableCount = response.data.data.total
          this.pendingPayment = response.data.data.diff
          this.pay_id = payment?.id
        }

        this.folio = payment.invoice
        this.count = `${payment.payment}`
        this.exchangeType = payment.exchange_rate
        this.paymentDate = payment.voucher_date

        if (this.exchangeType) {
          this.usdCount = true
        }

        this.selectedPaymentMethod = this.getPaymentMethods.find(
          method => method.id === payment.payment_method_id,
        )

        if (this.isSelectedPaymentMethodForBalances) {
          await this.loadCreditBalancesList()

          this.selectedOrderRefund = this.formatedOrdersRefunds.find(
            order => order.id === `${payment.positive_balance_resource_id}${payment.positive_balance_resource_type}`
            && order.type === payment.positive_balance_resource_type,
          )
        }

        this.invoiceRequired = payment.invoice_required

        const blob = await this.downloadImageBlob(payment.id)
        const base64 = await convertImageToBase64(blob)

        this.images = [{ imagesCode: base64, image: payment.image }]
        this.$refs['order-payment-modal'].show()
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      }
    },

    /**
    * Edicion de pago
    *
    * @summary Cargo los datos del pago a editar
    * @param {Object} payment - Objeto con los datos del pago
    * @exception - Muestra un mensaje de error si no se puede cargar el pago
    */
    async showModalAndLoadReportPayment(payment) {
      try {
        this.id = payment.IdQuote
        const response = await this.loadPaymentResume(payment.IdQuote)

        // Indica que la actualizaión del pago se hace desde la lista de reportes
        this.updateFromReports = true

        if (response) {
          this.availableCount = response.data.data.total
          this.pendingPayment = response.data.data.diff
          this.pay_id = payment?.IdPaymentKey
        }

        this.folio = payment.Invoice.value
        this.count = payment.Payment
        this.exchangeType = payment.ExchangeRate
        this.paymentDate = payment.VoucherDate

        if (this.exchangeType) {
          this.usdCount = true
        }

        this.selectedPaymentMethod = this.getPaymentMethods.find(
          method => method.method === payment.PaymentMethod,
        )

        if (this.isSelectedPaymentMethodForBalances) {
          await this.loadCreditBalancesList()

          this.selectedOrderRefund = this.formatedOrdersRefunds.find(
            order => order.id === `${payment.PositiveBalanceResourceId}${payment.PositiveBalanceResourceType}`
            && order.type === payment.PositiveBalanceResourceType,
          )
        }

        this.invoiceRequired = payment.InvoiceRequired

        const blob = await this.downloadImageBlob(payment.IdPaymentKey)
        const base64 = await convertImageToBase64(blob)

        this.images = [{ imagesCode: base64, image: payment.Image }]
        this.$refs['order-payment-modal'].show()
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      }
    },

    /**
    * Saldos a favor
    *
    * @summary Carga la lista de saldos a favor
    * @exception - Muestra un mensaje de error si no se puede cargar la lista
    */
    async loadCreditBalancesList() {
      try {
        const response = await this.loadCreditBalances(this.pay_id)
        this.ordersRefunds = response.data.data.credit_balances || []
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      }
    },

    /**
     * Limpia los campos del formulario y oculta el modal
     */
    onCancelPayment() {
      this.folio = ''
      this.count = ''
      this.images = []
      this.usdCount = false
      this.exchangeType = ''
      this.submitted = false
      this.invoiceRequired = 'No'
      this.selectedPaymentMethod = ''
      this.paymentDate = ''
      this.selectedOrderRefund = ''
      this.updateFromReports = false
      this.hideModal()
    },

    /**
     * Oculta el modal
     */
    hideModal() {
      this.$refs['order-payment-modal'].hide()
    },

    /**
     * Mensaje de confirmación
     *
     * @summary Muestra un mensaje de confirmación y si se acepta, se recarga la página
     * @param {String} message - Mensaje a mostrar
     */
    showSwalFire(message, paymentResponse = null) {
      this.$swal.fire({
        title: 'Guardado',
        text: message,
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
        allowEscapeKey: false,
        allowOutsideClick: false,
      }).then(result => {
        if (result.isConfirmed) {
          if (paymentResponse) {
            this.$emit('reload-payments',
              {
                ID: paymentResponse.ID,
                IdPaymentKey: this.pay_id,
                Image: paymentResponse.Image,
                Invoice: paymentResponse.Invoice,
                Payment: paymentResponse.Payment,
                Currency: paymentResponse.Currency,
                VoucherDate: paymentResponse.VoucherDate,
                ExchangeRate: paymentResponse.ExchangeRate,
                PaymentMethod: paymentResponse.PaymentMethod,
                InvoiceRequired: paymentResponse.InvoiceRequired,
                PaymentWithExchangeRate: paymentResponse.PaymentWithExchangeRate,
                PositiveBalanceResourceId: paymentResponse.PositiveBalanceResourceId,
                PositiveBalanceResourceType: paymentResponse.PositiveBalanceResourceType,
              },
              {
                paymentStatus: paymentResponse.PaymentStatus,
                percentagePaid: paymentResponse.PercentagePaid,
                positiveBalance: paymentResponse.CreditBalance,
              },
              paymentResponse.PaymentConfirmation)
          } else {
            this.$emit('reload-order')
          }
        }
      })
    },

    /**
     * Establece que se ha cargado la imagen
     */
    imageLoaded() {
      this.imagesLoaded = true
    },

    /**
    * Mensaje de notificación
    *
    * @summary Despliega un mensaje de notificación
    * @param {string} title - Título del mensaje de notificación
    * @param {string} text - Contenido del mensaje de notificación
    * @param {string} variant - Tipo de notificación
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
